$primaryColor: #1fa6d7;
$fontColor1: #101820;
$grayBackground: #f7f8fa;

.online-content {
  background: #fff;
  padding: 20px 16px;
  .form-container{
    display: flex;
    justify-content: flex-end;
  }
  .table-container {
    box-shadow: none;
  }
  .operation{
    .button:first-child{
      .extend{
        left: 0;
      }
    }
    .button:first-child:hover {
      .extend {
        max-width: 110px;
        width: 60px;
      }
    }
    
  }
}
.overview-data {
  margin-left: auto;
  color: #86909c;
  display: flex;
  div {
    padding: 10px 16px;
    margin-left: 10px;
    background-color: #f7f8fa;
    border-radius: 4px;
  }
  span {
    color: $primaryColor;
  }
}
.operation {
  display: flex;
  justify-content: flex-start;
}
.special-operation {
  text-align: center;
  font-size: 18px;
  .ant-table-filter-column {
    display: flex;
    justify-content: center;
    align-items: center;
    .ant-table-column-title {
      display: none;
    }
    .ant-table-filter-trigger {
      margin: 0;
    }
    .iconplus {
      color: #374151;
      font-size: 20px !important;
    }
  }
  .iconminus{
    font-size: 20px !important;
  }
}
.parameter{
  font-size: 14px;
  color: #1C64F2;
  cursor: pointer;
  
}
.statusNomal {
  display: inline-block;
  padding: 1px 8px;
  background: #edfff8;
  font-size: 12px;
  font-weight: normal;
  line-height: 20px;
  letter-spacing: -0.01px;
  color: #3db86d;
  border-radius: 20px;
}
.statusBack {
  padding: 1px 8px;
  background: #f0f5ff;
  font-size: 12px;
  font-weight: normal;
  line-height: 20px;
  letter-spacing: -0.01px;
  color: #1c64f2;
  border-radius: 20px;
}
.sortIcon {
  .iconfont {
    font-size: 19px;
  }
}
.algorithm {
  margin: 10px 0 15px;
}

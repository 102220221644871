$primaryColor: #1fa6d7;
$fontColor1: #101820;
$grayBackground: #f7f8fa;


.work-hour-table{
  .ant-table table {
    border-collapse: separate !important;
  }
  .ant-input-number-input{
    pointer-events: none;
    caret-color: transparent;
  }
  .ant-input-number-handler-wrap{
    opacity: 1;
  }
  .holiday {
    background-color: #F9FAFB;
    color: #9CA3AF;;
  }
}
$primaryColor: #1fa6d7;
$fontColor1: #101820;
$grayBackground: #f7f8fa;

.state-tab {
 width: 100%;
 padding-bottom: 20px;
 // overflow: hidden;

 .title-box {
  display: flex;
  align-items: center;
  height: 24px;

  .title-icon {
   width: 16px;
   height: 16px;
   margin-right: 5px;
  }
 }

 .state-box {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  .single-box {
   position: relative;
   height: 109px;

   &.active-box {
    .blur {
     display: block;
    }
   }

   &:hover {
    .up-bg {
     background: rgba(255, 255, 255, 0.95);

     &::before {
      pointer-events: none;
      position: absolute;
      content: "";
      border-style: solid;
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transform: translateY(15px);
      transform: translateY(15px);
      opacity: 1;
      left: calc(50% - 15px);
      bottom: 0;
      border-width: 15px 15px 0 15px;
      border-color: #fff transparent transparent transparent;
     }
    }

    .blur {
     display: block;
    }

   }

   .below-bg {
    position: absolute;
    width: 157px;
    height: 97px;
    top: 9px;
   }

   .up-bg {
    position: absolute;
    right: 0px;
    top: 0;
    bottom: 0px;
    left: 7px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 6px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    z-index: 1;

    .up-num {
     font-family: "Oswald";
     font-size: 40px;
     margin-bottom: 7px;
     margin-top: 7px;
    }

    .up-ch {
     font-size: 14px;
     font-weight: normal;
     color: #6B7280;
    }
   }

   .activeBg {
    background: url('~@/assets/img/projectcenter/active-bg.png') no-repeat;
    background-size: 100% 100%;
    backdrop-filter: blur(3px);
    border-radius: 6px;
    position: absolute;
    right: 0px;
    top: 0;
    bottom: -16px;
    left: 7px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    cursor: pointer;
    z-index: 1;
    color: #Fff;
    padding: 28px 0 0 20px;

    &::before {
     pointer-events: none;
     position: absolute;
     content: "";
     border-style: solid;
     -webkit-transform: translateY(15px);
     transform: translateY(15px);
     opacity: 1;
     left: calc(50% - 15px);
     bottom: 15px;
     border-width: 15px 15px 0 15px;
     border-color: rgba(63, 131, 248, 0.59) transparent transparent transparent;
    }

    .up-num {
     font-family: "Oswald";
     font-size: 40px;
     margin-bottom: 7px;
    }

    .up-ch {
     font-size: 14px;
     font-weight: normal;
    }

   }

   .blur {
    position: absolute;
    top: 85px;
    left: calc(50% - 55px);
    width:110px;
    height: 45px;
    opacity: 1;
    filter: blur(20px);
    display: none;
   }

  }
 }
}
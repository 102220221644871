$primaryColor: #1fa6d7;
$fontColor1: #101820;
$grayBackground: #f7f8fa;

// 项目看板
.board-container {
  display: flex;
  flex-wrap: wrap;
  min-width: 1220px;
  gap: 12px 16px;
  justify-content: center;
  // margin-top: 20px;
}
// 卡片容器
.card-container {
  background: white;
  box-shadow:
    0px 3px 20px 0px rgba(6, 33, 74, 0.1),
    inset 0px 0px 0px 1px #ffffff;
  border-radius: 2px;
  padding: 10px 16px;
  .statistic-head {
    display: flex;
    justify-content: space-between;
    height: 41px;
    align-items: center;
    // margin-top: 5px;
    border-bottom: 1px solid#f3f4f6;
    .statistic-head-left {
      display: flex;
      gap: 5px;
      padding-bottom: 10px;
      span {
        font-weight: 500;
        font-size: 16px;
        font-family: "思源黑体 CN";
      }
    }
    .statistic-head-right {
      display: flex;
      align-items: center;
      padding-bottom: 10px;
      .select-desc {
        width: 70px;
      }
    }
  }
}
// 项目状态统计
.project-status-container {
  display: flex;
  padding-top: 15px;
  width: 98%;
  justify-content: space-around;
  gap: 15px;
  .grid-container {
    width: 25%;
    height: 126px;
    border: 1px solid #dddddd;
    background: white;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    .rect-block {
      width: 100%;
      height: 6px;
    }
    .project-num {
      font-family: "Roboto";
      font-size: 24px;
      font-weight: bold;
      line-height: 28px;
      color: #3d3d3d;
      padding: 20px 0 0 16px;
    }
    .project-status-type {
      font-family: "思源黑体 CN";
      font-size: 14px;
      font-weight: normal;
      padding: 10px 0 0 16px;
      color: #9ca3af;
    }
    img {
      position: absolute;
      left: 15px;
      bottom: -22px;
      width: 150px;
      height: 89px;
    }
  }
}
// 项目类型统计
.project-type-container {
  margin-top: 5px;
  display: flex;
  height: 158px;
  align-items: center;
  gap: 25px;
  overflow-y: hidden;
  overflow-x: scroll;
  .project-name {
    font-family: "思源黑体 CN";
    font-size: 12px;
    line-height: 22px;
    color: #3d3d3d;
    text-align: center;
    min-height: 23px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 80px;
  }
  .project-type-circle-container {
    cursor: pointer;
    height: 150px;
    padding-bottom: 20px;
  }
}

// 项目进度跟踪统计
.project-progress-container {
  display: flex;
  height: 165px;
  .project-progress-desc {
    width: 8%;
    text-align: right;
    line-height: 22px;
    font-size: 14px;
    font-family: "思源黑体 CN";
    display: flex;
    flex-direction: column;
    gap: 45px;
    justify-content: flex-end;
    margin-bottom: 30px;
    padding-right: 16px;
    .project-progress-tip {
      min-width: 100px;
    }
  }
  .grid-list {
    width: 92%;
    display: flex;
    margin-left: 40px;
    overflow-x: auto;
    .grid-container {
      display: flex;
      justify-content: left;
      align-items: center;
      position: relative;
      margin: 0 0 24px 0;
      width: 16%;
      cursor: pointer;
      .progress-item {
        width: 50%;
        margin-top: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          width: 76px;
          height: 88px;
          margin-bottom: 17px;
        }
        &:hover {
          border-radius: 3px;
          background: rgba(31, 103, 242, 0.05);
        }
        .project-num {
          position: absolute;
          top: 42px;
          color: #f9fafb;
          font-family: "Roboto";
          font-size: 18px;
        }
        .progress-name {
          text-align: center;
          padding-bottom: 10px;
          width: 100px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        &:hover .progress-name {
          color: #3f83f8;
        }
      }
      .progress-arrow {
        width: 50%;
        margin-bottom: 36px;
        padding: 0 25px 0 15px;
        display: flex;
        justify-content: center;
        img {
          width: 76px;
          height: 32px;
        }
      }
    }
  }
}

// 近12个月项目开展趋势
.project-trend-container {
}
// 行业分布
.industry-distribution-container {
}

// 项目列表
.page-container {
  width: 100%;
  background: white;
  box-shadow:
    0px 3px 20px 0px rgba(6, 33, 74, 0.1),
    inset 0px 0px 0px 1px #ffffff;
  border-radius: 2px;
  margin: auto;
  // margin-top: 28px;
  padding: 15px;

  .project-list-table {
    .operation {
      .button:first-child {
        .extend {
          left: 0;
        }
      }
      .button:first-child:hover {
        .extend {
          max-width: 110px;
          width: 80px;
        }
      }
    }
  }
  // 查询条件容器
  .form-container {
    display: flex;
    justify-content: flex-end;
    .custom-channel-search {
      width: 354px;
      button {
        border-left: none;
      }
    }
    .project-board-list-form {
      gap: 25px;
    }
  }
  // 表格显示容器
  .table-container {
    margin-top: 18px;
    .custom-table {
      width: 100%;
      overflow-x: auto;
      .ant-table-header {
        height: 46px;
      }
      .ant-table-cell {
        height: 46px !important;
      }
      .projectStatus0,
      .projectStatus1,
      .projectStatus2,
      .projectStatus3,
      .projectStatus4,
      .projectStatus5 {
        border-radius: 10px;
        height: 22px;
        font-family: "思源黑体 CN";
        font-size: 12px;
        line-height: 20px;
        text-align: center;
      }
      // 未开始
      .projectStatus0 {
        background: #e6fffb;
        color: #16bdca;
      }
      // 进行中
      .projectStatus1 {
        background: #edfff8;
        color: #3db86d;
      }
      // 未开始 已延期
      .projectStatus3 {
        background: #fff7e6;
        color: #f3911a;
      }
      // 进行中-已延期
      .projectStatus2 {
        background: #fff1f0;
        color: #f5222d;
      }

      // 已结项
      .projectStatus4 {
        background: #f9f0ff;
        color: #722ed1;
      }
      // 关闭
      .projectStatus5 {
        background: #efefef;
        color: #4b5563;
      }
    }
    
    .is-over {
      display: flex;
      .cost_red {
        color: #E73737;
      }
      .iconfont {
        margin-left: 8px;
        cursor: pointer;
      }
    }
  }
}

// 详情页-甘特图
.project-detail-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  // 顶部项目信息
  .content-top {
    width: 1220px;
    height: 94px;
    border-radius: 2px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow:
      0px 3px 20px 0px rgba(6, 33, 74, 0.1),
      inset 0px 0px 0px 1px #ffffff;
    .project-info-left {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 19px 15px;
      span {
        line-height: 22px;
        color: #111928;
        font-family: "思源黑体 CN";
        font-size: 14px;
      }
    }
    .project-info-right {
      display: flex;
      gap: 12px;
      margin-right: 15px;
      .card-style {
        position: relative;
        img {
          width: 100px;
          height: 72px;
        }
        .desc-info {
          position: absolute;
          display: flex;
          flex-direction: column;
          top: 12px;
          left: 12px;
          .desc-num {
            line-height: 24px;
            color: #111928;
            font-weight: bold;
            font-size: 18px;
            font-family: "Oswald";
          }
          .desc-title {
            line-height: 20px;
            color: #4b5563;
            font-family: "思源黑体 CN";
          }
        }
      }
    }
  }
  // 甘特图表
  .content-bottom {
    width: 1220px;
    height: 600px;
    background: #ffffff;
  }
}

$primaryColor: #1fa6d7;
$fontColor1: #101820;
$grayBackground: #f7f8fa;

.type-content{
  .table-container{
    margin-top: 15px;
    padding: 20px 15px;
    background: #fff;
  }
  .operation{
    .button:first-child{
      .extend{
        left: 0;
      }
    }
    .button:first-child:hover {
      .extend {
        max-width: 110px;
        width: 60px;
      }
    }
  }
}

$primaryColor: #1fa6d7;
$fontColor1: #101820;
$grayBackground: #f7f8fa;

.my-exam {
 padding: 20px 15px;
 background: #fff;
 overflow: auto;
 box-shadow: 0px 3px 20px 0px rgba(6, 33, 74, 0.1);

 .exam-title {
  font-size: 16px;
  color: #1F2A37;

  .iconfont {
   color: #3F83F8;
   margin-right: 5px;
   font-weight: 500;
  }
 }

 .form-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .ant-select {
   margin: 0 10px;
   width: 140px;
  }

  .ant-input-group-wrapper {
   height: 32px;
   width: 354px;
   cursor: pointer;

   .ant-input-affix-wrapper {
    border-radius: 2px 0 0 2px !important;
    height: 32px;
   }

   .ant-input-group-addon {
    .ant-btn {
     border-left: none;
     border-radius: 0 2px 2px 0;

     &:hover {
      border: 1px solid #4096ff;
     }
    }
   }
  }



  .creation {
   display: flex;
   align-items: center;
   font-size: 14px;

   i {
    font-size: 14px;
    margin-left: 3px;
   }
  }
 }

 .ant-table-thead {
  font-weight: normal;
  height: 44px !important;
  border: 1px solid #e3e3e3;
 }

 .ant-table-thead>tr>th {
  padding: 10px 8px !important;
 }

 .ant-table-body {
  height: auto;
 }

 .ant-table-row {
  .ant-table-cell {
   padding: 0 16px;
   height: 44px !important;
  }
 }

 .ant-table-expanded-row {
  .ant-table-thead {
   height: 33px !important;

   .ant-table-cell {
    padding: 0 16px;
    font-weight: normal;
   }
  }

  .light-text {
   font-size: 14px;
   font-weight: normal;
   color: #9ca3af;
  }

  .ant-table-tbody {
   .ant-table-cell {
    padding: 16px;
    background: #fff !important;
   }
  }
 }

 .ant-table-expanded-row-fixed {
  padding: 0;
 }

}
$primaryColor: #1fa6d7;
$fontColor1: #101820;
$grayBackground: #f7f8fa;

.gantt-page{
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  .export-gantt {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 12px;
    .iconfont {
      margin-left: 3px;
    }
  }
  .project-header{
    background: #fff;
    height: 94px;
    box-shadow: 0px 3px 20px 0px rgba(6, 33, 74, 0.1),inset 0px 0px 0px 1px #FFFFFF;
    margin-bottom: 10px;
    padding: 11px 15px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .project-left{
      font-size: 14px;
      font-weight: normal;
      line-height: 22px;
      letter-spacing: -0.01px;
      color: #111928;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
    .project-right{
      display: grid;
      grid-template-columns: repeat(4,1fr);
      column-gap:12px;
      .project-content{
        width: 100px;
        height: 72px;
        border-radius: 2px;
        padding: 12px;
        box-sizing: border-box;
        border: 1px solid #E5E7EB;
        .num{
          font-size: 18px;
          font-weight: bold;
          letter-spacing: 0em;
          color: #111928;
          margin-bottom: 4px;
        }
        .title{
          font-size: 12px;
          font-weight: normal;
          line-height: 20px;
          letter-spacing: -0.01px;
          color: #4B5563; 
        }
      }
      .member{
        background-image: url("../../assets/img/gantt/member.png");
        background-size: 100% 100%;
      }
      .file{
        background-image: url("../../assets/img/gantt/file.png");
        background-size: 100% 100%;
      }
      .device{
        background-image: url("../../assets/img/gantt/device.png");
        background-size: 100% 100%;
      }
      .product{
        background-image: url("../../assets/img/gantt/product.png");
        background-size: 100% 100%;
      }
    }
  }
  .gantt-content{
    height: 100%;
    display: flex;
    .gantt-title{
      // width: 500px !important;
      height: 100%;
      background: #fff;
      flex-grow: 0;
      flex-shrink: 1;
      // margin-right: 10px;
      .header{
        width: 100%;
        height: 60px;
        box-sizing: border-box;
        padding: 14px 15px;
        background: #fff;
        margin: 0;
        display: flex;
        justify-content: space-between;
        .project-name{
          font-size: 16px;
          font-weight: normal;
          line-height: 24px;
          letter-spacing: 0px;
          color: #111928;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .project-statuses{
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .stages{
        display: flex;
        border-top: 1px solid #E5E7EB;
        overflow: hidden;
        background: #fff;
        .stageName{
          width: 64px;
          .stage{
            padding: 0 15px;
            font-size: 16px;
            font-weight: normal;
            line-height: 24px;
            letter-spacing: 0px;
            color: #111928;
            width: 100%;
            display: flex;
            align-items: center;
            box-shadow: 1px 0px 0px 0px #E5E7EB;
            flex-shrink: 0;
            border-bottom: 1px solid #E5E7EB;
            .stage-title{
              width: 100%;
              text-align: center;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              cursor: default;
            }
          }
        }
        .stageName:first-child{
          padding-top: 4px;
        }
        
        .stages-line{
          border-left: 1px solid #E5E7EB;
          cursor: col-resize;
          overflow: hidden;
          user-select: none;
        }
        .tasks{
          width: 100%;
          .node-item{
            height: 42px;
            padding: 9.8px 5px;
            border-bottom: 1px solid #E5E7EB;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .node-title{
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              cursor: default;
            }
          }
          .node-item:first-child{
            height: 46px;
          }
        }
      }
      .empty {
        width: 100%;
        height: 150px;
        background: #fff;
      }
    }
    .gantt-line{
      border-left: 1px solid #fff;
      height: 100%;
      margin-right: 10px;
      cursor: col-resize;
      overflow: hidden;
      user-select: none;
    }
    .gantt-part{
      width: 100%;
      height: 100%;
      background: #fff;
      #gantt{
        width: calc(100% - 200px);
        .gantt-container{
          .grid-header{
            stroke: #E5E7EB;
          }
          .gantt .grid-row:nth-child(even) {
            fill:#fff
          }
        }
      }
      .details-container{
        padding: 10px 15px;
        background: #fff;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.08);
        h5{
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: -0.01px;
          color: #374151;
        }
        p{
          margin: 0;
          font-size: 12px;
          font-weight: normal;
          line-height: 20px;
          letter-spacing: -0.01px;
        };
        button{
          border: none;
          background: #fff;
          color: #3f83f8;
          cursor: pointer;
          margin-right: 5px;
        }
      }
    }
  }
}
.project-delay{
  display: inline-block;
  padding: 1px 8px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: normal;
  line-height: 20px;
  letter-spacing: -0.01px;
  color: #F5222D;
  background: #FFF1F0;
  margin-left: 2px;
}
.status{
  display: inline-block;
  padding: 1px 8px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: normal;
  line-height: 20px;
  letter-spacing: -0.01px;
  white-space: nowrap;
}



.planStartDelay .bar {
  fill: #FFF0DE !important;
  stroke: #FFF0DE;
  stroke-dasharray: 0;
}
.planStartDelay .bar-label   {
  fill: #FFAE4E !important;
  font-weight: normal;
}

.planStart .bar {
  fill: #D5F5F6 !important;
  stroke: #D5F5F6;
  stroke-dasharray: 0;
}
.planStart .bar-label   {
  fill: #16BDCA !important;
  font-weight: normal;
}

.start .bar {
  fill: #16BDCA !important;
  stroke: #16BDCA;
  stroke-dasharray: 0;
}
.start .bar-label   {
  fill: #fff !important;
  font-weight: normal;
}

.startDelay .bar {
  fill: #FFBF73 !important;
  stroke: #FFBF73;
  stroke-dasharray: 0;
}
.startDelay .bar-label   {
  fill: #fff !important;
  font-weight: normal;
}

.planProgressDelay .bar {
  fill: #FDE8E8 !important;
  stroke: #FDE8E8;
  stroke-dasharray: 0;
}
.planProgressDelay .bar-label   {
  fill: #F05252 !important;
  font-weight: normal;
}

.planProgress .bar {
  fill: #DEF7EC !important;
  stroke: #DEF7EC;
  stroke-dasharray: 0;
}
.planProgress .bar-label   {
  fill: #3DB86D !important;
  font-weight: normal;
}

.progressDelay .bar {
  fill: #EB6B6B !important;
  stroke: #EB6B6B;
  stroke-dasharray: 0;
}
.progressDelay .bar-label   {
  fill: #fff !important;
  font-weight: normal;
}

.progress .bar {
  fill: #60CA89 !important;
  stroke: #60CA89;
  stroke-dasharray: 0;
}
.progress .bar-label   {
  fill: #fff !important;
  font-weight: normal;
}

.finish .bar {
  fill: #5D96F9 !important;
  stroke: #5D96F9;
  stroke-dasharray: 0;
}
.finish .bar-label   {
  fill: #fff !important;
  font-weight: normal;
}

.noPlan .bar {
  fill: #E5E7EB !important;
  stroke: #E5E7EB;
  stroke-dasharray: 0;
}
.noPlan .bar-label   {
  fill: #374151 !important;
  font-weight: normal;
}

.gantt .today-highlight{
  width: 1px;
  stroke: #3F83F8 !important;
  stroke-width: 1px;
  stroke-opacity: 1;
}
.taskBorder .bar{
  stroke: #5D96F9;
  stroke-width: 2px;
}

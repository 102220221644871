$primaryColor: #1fa6d7;
$fontColor1: #101820;
$grayBackground: #f7f8fa;

.tab-approve-box-list {
  display: flex;
  gap: 5px;
  overflow-x: auto;
  .tab-block {
    min-width: 45px;
    height: 76px;
    border-radius: 1px;
    background: #f3f4f6;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 6px;
    position: relative;
    cursor: pointer;
    .active-bg {
      width: 45px;
      height: 76px;
      background: #0256f8;
      position: absolute;
      opacity: 0.1;
      top: 0;
    }
    .date-item {
      text-align: center;
      display: flex;
      flex-direction: column;
      position: absolute;
      gap: 5px;
      .item-month {
        font-family: "Source Han Sans CN";
        font-size: 12px;
        // line-height: 20px;
        // display: flex;
        color: #374151;
      }
      .item-date {
        font-family: "Source Han Sans CN";
        font-size: 18px;
        font-weight: bold;
        color: #374151;
      }
      .item-status {
        font-family: "Source Han Sans CN";
        font-size: 12px;
        color: #6b7280;
      }
    }
    &.tab-block-active {
      .item-month,
      .item-date,
      .item-status {
        color: #0256f8;
      }
    }
  }
}

$primaryColor: #1fa6d7;
$fontColor1: #101820;
$grayBackground: #f7f8fa;

.main-page-equipment {
  padding: 15px;
  background: #fff;
  .form-container {
    display: flex;
    justify-content: flex-end;
  }
  .overview-data {
    margin-left: auto;
    color: #86909c;
    display: flex;
    div {
      padding: 10px 16px;
      margin-left: 10px;
      background-color: #f7f8fa;
      border-radius: 4px;
    }
    span {
      color: $primaryColor;
    }
  }

  .button:first-child {
    .extend {
      left: 0;
      top: 0;
    }
  }
  .button:hover {
    .extend {
      width: 150px;
    }
  }
  .blue-text {
    color: #1c64f2;
    cursor: pointer;
    text-decoration: underline;
    cursor: pointer;
  }
  .center-status {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .green-status {
      background: #edfff8;
      color: #3db86d;
    }
    .red-status {
      background: #fff1f0;
      color: #f5222d;
    }
    .common-status {
      font-size: 12px;
      font-weight: normal;
      line-height: 22px;
      letter-spacing: -0.01px;
      width: 40px;
      height: 22px;
      border-radius: 20px;
      padding: 1px 8px;
    }
  }
  .operation {
    display: flex;
    justify-content: flex-start;
  }
  .version {
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
    letter-spacing: -0.01px;
    color: #111928;
    margin-bottom: 15px;
  }
  .main-page {
    padding: 15px;
    background: #fff;
  }
}
.checkParameter{
  cursor: pointer;
  color: #3f83f8;
}
$primaryColor: #1fa6d7;
$fontColor1: #101820;
$grayBackground: #f7f8fa;

.submit-modal {
  .ant-modal-content {
    padding: 0;
  }
  .title {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0px;
    color: #1F2A37;
    padding: 36px 0 36px;
  }
  .footer {
    display: flex;
    height: 50px;
    border-top: 1px solid #E5E7EB;
    cursor: pointer;
    .cancel {
      width: 50%;
      height: 100%;
      font-size: 16px;
      letter-spacing: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid #E5E7EB;
      color: #6B7280;
    }
    .confirm {
      width: 50%;
      height: 100%;
      font-size: 16px;
      letter-spacing: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #0A73FF;
    }
  }
}
.timeOver-modal {
  .ant-modal-content {
    padding: 50px 0;
  }
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .tip {
    line-height: 24px;
    color: #6B7280;
    margin-top: 9px;
  }
}
.noExamInfo-modal {
  .ant-modal-content {
    padding: 30px 0;
  }
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .image {
    width: 209.15px;
    height: 120px;
  }
  .tip {
    font-size: 16px;
    line-height: 24px;
    color: #6B7280;
    margin: 9px 0 24px;
  }
}
.score {
  white-space: nowrap;
  .ant-modal-content {
    padding: 0;
    overflow: hidden;
  }
  .ant-modal-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .header {
    width: 100%;
    height: 140px;
    background: #3F83F8;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .content {
      position: relative;
      .circle {
        width: 106px;
        height: 106px;
        border-radius: 50%;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, transparent 100%);
        -webkit-mask: radial-gradient(transparent, transparent 47px, #000 47px);
        mask: radial-gradient(transparent 47px, #000 47px);
      }
      .title {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
        padding: 3px 7px;
        font-size: 11px;
        font-weight: normal;
        text-wrap: nowrap;
        line-height: 15px;
        letter-spacing: 0px;
        color: #FFFFFF;
        z-index: 10;
        background: #FFC32A;
        border-radius: 89px;
      }
      .score {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        color: #FFFFFF;
        .num {
          font-family: 'Source Han Sans';
          font-size: 28px;
          font-weight: 700;
          line-height: 28px;
          letter-spacing: 1px;
        }
        .unit {
          font-size: 14px;
          font-weight: bold;
          line-height: 28px;
          letter-spacing: 1px;
        }
      }
    }
  }
  .image {
    margin: 35px 0;
  }
  .pass-operate{
    width: 280px;
    height: 44px;
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 1px;
    margin-bottom: 25px;
  }
  .mobile-unqualified-operate {
    display: flex;
    gap: 12px;
    margin-bottom: 25px;
    button {
      font-size: 14px;
      font-weight: normal;
      line-height: normal;
      letter-spacing: 1px;
      width: 134px;
      height: 42px;
    }
  }
  .unqualified-operate {
    display: flex;
    gap: 20px;
    margin-bottom: 25px;
    button {
      width: 180px;
      height: 44px;
    }
  }
}
$primaryColor: #1fa6d7;
$fontColor1: #101820;
$grayBackground: #f7f8fa;

.my-task {
  .main-box {
    padding: 15px 15px 20px 15px;
    background: #fff;
    margin-top: 15px;
    box-shadow: 0px 3px 20px 0px rgba(6, 33, 74, 0.1);
    .select-line{
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
    }
  }

  .operation {
    display: flex;
    justify-content: flex-start;

    .button:first-child {
      .extend {
        left: 0;
        top: 0;
      }
    }

    .button:hover {
      .extend {
        width: 60px;
      }
    }
  }
}
.work-hour-modal {
  .ant-modal-content {
    // width: 562px;
  }
  .work-hour-tip{
    font-size: 12px;
    font-weight: normal;
    display: flex;
    align-items: center;
    letter-spacing: -0.01px;
    color: #E73737;
    .icon{
      transform: scale(0.5);
    }
  }
  .tab-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
    height: 36px;
    padding-bottom: 10px;
    overflow: auto;
    overflow-y: hidden;
    height: 50px;
    .tab-list {
      margin-right: 30px;
      cursor: pointer;
      font-size: 14px;
      font-family: "Source Han Sans CN";
      padding-bottom: 5px;
      border-bottom: 3px solid #fff;
      position: relative;
      text-align: center;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 150px;
      flex-shrink: 0;
      &.tab-active {
        border-bottom: 3px solid #1c64f2;
        &::after {
          pointer-events: none;
          position: absolute;
          content: "";
          border-style: solid;
          -webkit-transform: translateY(6px);
          transform: translateY(6px);
          opacity: 1;
          left: calc(50% - 5px);
          bottom: -1px;
          border-width: 6px 6px 0 6px;
          border-color: #1c64f2 transparent transparent transparent;
        }
      }
    }
  }
  .report-work-hour{
    .search-line {
      display: flex;
      justify-content: flex-end;
      margin-top: 15px;
    }
    .work-hour-tip{
      font-size: 12px;
      font-weight: normal;
      display: flex;
      align-items: center;
      letter-spacing: -0.01px;
      color: #E73737;
      margin-bottom: 10px;
      .icon{
        transform: scale(0.5);
      }
    }
    .head-row{
      padding: 0 10px;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0px;
      color: #374151;
      height: 46px;
      line-height: 46px;
      background: #F3F4F6;
      border: 1px solid #E3E3E3;
      margin-top: 13px;
      .head-col{
        padding-left: 10px;
        border-left: 1px solid #E3E3E3 ;
      }
    }
    .task-list{
      height: 300px;
      overflow-y: scroll;
      .row{
        border: 1px solid #E3E3E3;
        border-top: none;
        padding: 8px 10px;
        .task-name{
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          display: flex;
          align-items: center;
          letter-spacing: -0.01px;
          color: #374151;
          word-wrap: break-word;
          word-break: break-all;
        }
        .project-name{
          font-size: 12px;
          font-weight: normal;
          line-height: 20px;
          display: flex;
          align-items: center;
          letter-spacing: -0.01px;
          color: #9CA3AF;
          word-wrap: break-word;
          word-break: break-all;
        }
        .col{
          padding-left: 10px;
          display: flex;
          align-items: center;
          .input-number{
            border-radius: 2px;
          }
          .ant-input-number-input{
            pointer-events: none;
            caret-color: transparent;
          }
          .ant-input-number-handler-wrap{
            opacity: 1;
          }
        }
      }
    }
    
  }
}
.top-up-style {
  background: linear-gradient(0deg, rgba(63, 131, 248, 0.05), rgba(63, 131, 248, 0.05)), #FFFFFF;
  .ant-table-cell-fix-left, .ant-table-cell-fix-right {
    background: linear-gradient(0deg, rgba(63, 131, 248, 0.05), rgba(63, 131, 248, 0.05)), #FFFFFF;
  }
}
$primaryColor: #1fa6d7;
$fontColor1: #101820;
$grayBackground: #f7f8fa;

.my-hour {
 padding: 20px 15px;
 background: #fff;
 overflow: auto;
 box-shadow: 0px 3px 20px 0px rgba(6, 33, 74, 0.1);

 .form-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;

  .staff-range-picker {
   border-color: #e5e7eb !important;
   border-radius: 2px;
   box-sizing: border-box;
   border: 1px solid #e5e7eb;
   width: 250px;
   margin-right: 10px;
  }

  .search-button {
   color: #1C64F2;
   border-radius: 2px;
   border: 1px solid #1C64F2;
  }

 }

 .ant-table-thead {
  box-shadow: none !important;
  .ant-table-cell {
   border: 1px solid #d9dce1;
  }
 }

 .hour-total {
  background: rgba(2, 86, 248, 0.08);
  border: 1px solid rgba(2, 86, 248, 0.4);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 10px;
  height: 36px;
  margin-top: 15px;
 }


}

.hour-detail {
 .hour-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
 }

 .ant-select {
  margin: 0 10px;
  width: 110px;
  border-radius: 2px;

 }
}
$primaryColor: #1fa6d7;
$fontColor1: #101820;
$grayBackground: #f7f8fa;

.channelManagement {
  width: 100%;
  background: #fff;
  padding: 15px;
  .channel_head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
    .add_icon {
      font-size: 14px;
    }
    .custom-channel-personel-search {
      width: 354px;
      button {
        border-left: none;
      }
    }
  }
  .personal-table,
  .market-table,
  .channel_table {
    .operation {
      .button:first-child {
        .extend {
          left: 0;
        }
      }
      .button:first-child:hover {
        .extend {
          max-width: 110px;
          width: 60px;
        }
      }
    }
  }
  .channel_page {
    text-align: right;
    margin-top: 10px;
  }
  .basic_tabs {
    width: 520px;
    .basic-custom-table-cell {
      padding: 10px 16px 10px 16px;
    }
    .operation {
    }
    .mouse {
      font-size: 20px !important;
    }
    .custom-table {
      .ant-table-thead {
        .ant-table-cell {
          padding: 9px 16px 9px 16px;
        }
      }
    }
    .editable-cell {
      position: relative;
    }

    .editable-cell-value-wrap {
      padding: 5px 12px;
      cursor: pointer;
    }

    .editable-row:hover .editable-cell-value-wrap {
      padding: 4px 11px;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
    }
  }
  .MarketTabs {
    .status1,
    .status2 {
      background: #edfff8;
      border-radius: 20px;
      font-family: "思源黑体 CN";
      font-size: 12px;
      text-align: center;
      width: 52px;
    }
    .status1 {
      background: #edfff8;
      color: #3db86d;
      width: 40px;
    }
    .status2 {
      background: #fff1f0;
      color: #f5222d;
    }
  }
  // 人员管理
  .Personnel-Tabs {
    .status1,
    .status2 {
      background: #edfff8;
      border-radius: 20px;
      font-family: "思源黑体 CN";
      font-size: 12px;
      text-align: center;
      width: 52px;
    }
    .status1 {
      background: #edfff8;
      color: #3db86d;
      width: 40px;
    }
    .status2 {
      background: #fff1f0;
      color: #f5222d;
    }
  }
}
// 新增人员-新增销售工具
.add-person-modal,
.add-tool-modal {
  .ant-row {
    margin-left: -10px;
  }
  width: 413px !important;

  .ant-select {
    width: 287px !important;
  }
  .ant-picker {
    width: 287px !important;
  }
}

*,
*::before,
*::after {
 padding: 0;
 margin: 0;
 box-sizing: border-box;
}

p {
 margin: 0;
}

// 可编辑单元格统一样式
.editable-cell {
 position: relative;
}

.editable-cell-value-wrap {
 cursor: pointer;
 min-height: 24px;
 line-height: 24px;
}

.editable-row:hover .editable-cell-value-wrap {
 min-height: 24px;
 line-height: 24px;
 box-sizing: border-box;
 border-radius: 4px;
 background-color: #10182010;
}

// 自定义公共modal样式
.custom-modal {
 .ant-form-item {
  margin-bottom: 15px;
 }

 .ant-modal-content {
  border-radius: 2px;
  padding: 0 0 20px 0 !important;

  .ant-modal-close-x {
   color: #374151;
  }

  .ant-modal-header {
   border-bottom: 1px solid #e5e7eb;
   padding: 13px 20px;

   .ant-modal-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    color: #374151;
   }
  }

  .ant-modal-body {
   padding: 15px 20px 0px;

   .dialog-footer {
    display: flex;
    justify-content: flex-end;

    .ant-btn {
     border-radius: 2px;
     width: 91px;
     height: 32px;
     display: flex;
     align-items: center;
     justify-content: center;

     .iconfont {
      font-size: 14px;
      margin-right: 5px;
     }
    }

    .confirm {
     margin-left: 5px;
     color: #fff;
    }
   }
  }

  .ant-modal-footer {
   padding: 0 20px;

   .iconfont {
    font-size: 14px !important;
    margin-right: 3px;
   }
  }
 }

 // 自定义公共分页样式
 .custom-pagination {
  display: flex;
  justify-content: flex-end;

  .ant-pagination-item {
   border: 1px solid #d9d9d9;
   border-radius: 2px !important;
  }

  .ant-pagination-next,
  .ant-pagination-prev .ant-pagination-item-link {
   border: 1px solid #d9d9d9;
   border-radius: 2px !important;
  }
 }
}

// 自定义公共分页样式
.custom-pagination {
 display: flex;
 justify-content: flex-end;
 margin-top: 20px;

 .ant-pagination-item {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
 }

 .ant-pagination-next,
 .ant-pagination-prev .ant-pagination-item-link {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
 }

 .ant-pagination-item-active {
  border: 1px solid #1c64f2 !important;
  background: #1c64f2 !important;
  color: #ffffff !important;
 }
}

// 自定义textarea样式
.custom-textarea {
 textarea {
  padding-bottom: 22px;
  background-color: #ffffff00;
 }
}

.custom-textarea::after {
 margin-top: -22px;
 margin-right: 11px;
 position: relative;
}

// 统一模块标题
.module-title {
 color: $fontColor1;
 padding-left: 10px;
 position: relative;
}

.module-title::before {
 content: "";
 position: absolute;
 width: 4px;
 height: 14px;
 top: 4px;
 left: 0;
 background-color: $primaryColor;
}

.ant-table table {
 border-collapse: collapse !important;
}

.node-status {
 display: inline-block;
 height: 22px;
 line-height: 22px;
 padding: 0 8px;
 border-radius: 20px;
 font-size: 12px;

 &.yellow {
  background: #fff7e6;
  color: #f3911a;
 }

 &.teal {
  background: #e6fffb;
  color: #16bdca;
 }

 &.red {
  color: #f5222d;
  background: #fff1f0;
 }

 &.green {
  color: #3db86d;
  background: #edfff8;
 }

 &.purple {
  color: #722ed1;
  background: #f9f0ff;
 }

 &.gray {
  color: #4b5563;
  background: #efefef;
 }

 &.blue {
  background: #f0f5ff;
  color: #1c64f2;
 }
}

.custom-table {
 border-radius: 1px !important;
 overflow: inherit;
 box-sizing: border-box;

 .ant-table-row {
  // height: 47px;
 }

 .ant-table-header {
  border: 1px solid #d9dce1;
 }

 .ant-table-fixed-column {
  .ant-table-thead {
   box-shadow: none;

   .ant-table-cell {
    border: 1px solid #d9dce1;
   }
  }
 }

 .ant-table-thead {
  width: 100%;
  box-sizing: border-box;
  border-collapse: separate !important;
  // height: 47px;
  overflow: inherit !important;
  box-shadow: -1px 0px 0px 0px #e3e3e3, 1px 0px 0px 0px #e3e3e3,
   0px -1px 0px 0px #e3e3e3, 0px 1px 0px 0px #e3e3e3;

  .ant-table-cell {
   border-right: 1px solid #d9dce1;
   border-radius: 1px !important;
   background: #f3f4f6;
   color: #111928;
   font-size: 16px;
   font-weight: normal;
   line-height: 24px;
   letter-spacing: 0px;
  }
 }

 .ant-table-row-selected td {
  background: #fff !important;
 }

 .ant-pagination-item {
  border: 1px solid #d9d9d9;
  border-radius: 2px !important;
 }

 .ant-pagination-next,
 .ant-pagination-prev .ant-pagination-item-link {
  border: 1px solid #d9d9d9;
  border-radius: 2px !important;
 }

 .ant-pagination-item-active {
  border: 1px solid #1c64f2 !important;
  background: #1c64f2 !important;
  color: #ffffff !important;
 }
}

.ant-table-wrapper .ant-table .ant-table-header {
 border-radius: 1px !important;
}

.ant-btn-primary {
 text-shadow: none;
}

.ant-form-inline {
 gap: 4px;
}

.ant-form-inline .ant-form-item {
 // margin-right: 24px;
 // margin-bottom: 8px !important;
 margin-inline-end: 0;
}

.ant-input-group {
 border-radius: 2px !important;
}

.ant-input {
 border-radius: 2px !important;
}

.ant-input-search :where(.css-dev-only-do-not-override-k7429z).ant-input-group .ant-input-affix-wrapper:not(:last-child) {
 border-start-start-radius: 2px !important;
 border-end-start-radius: 2px !important;
}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
 border-start-end-radius: 2px;
 border-end-end-radius: 2px;
}

.ant-select-single .ant-select-selector {
 border-radius: 2px !important;
}

.ant-form-inline .ant-form-item:nth-last-child(2) {
 margin-right: 0 !important;
}

.ant-form-item-control-input {
 min-height: 24px;
}

//radio
.ant-radio-inner {
 background: #fff;

 &::after {
  background: #1c64f2;
 }
}

.mouse {
 cursor: pointer;
}

:where(.css-dev-only-do-not-override-12jzuas).ant-input-textarea-show-count .ant-input-data-count {
 bottom: 0 !important;
 right: 3px !important;
}


.confirmation {
 .ant-modal-confirm-body {
  flex-wrap: nowrap;
  padding: 5px 10px 5px 0 ;
  align-items: flex-start;
  .confont{
   font-size: 16px;
  }
  .ant-modal-confirm-title {
   font-size: 16px;
   font-weight: 500 !important;
  }

  .ant-modal-confirm-btns {
   display: flex;
   justify-content: flex-end;

   .ant-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    .icon-x{
     color: "#111827",
    }
    &:hover{
     .icon-x{
      color: #4096ff !important;
     }
    }
   }
  }
 }
}
$primaryColor: #1fa6d7;
$fontColor1: #101820;
$grayBackground: #f7f8fa;

// 产能人效主页
.efficiency-page-container {
  width: 100%;
  padding-bottom: 20px;
  background: white;
  box-shadow: 0px 3px 20px 0px rgba(6, 33, 74, 0.1),
    inset 0px 0px 0px 1px #ffffff;

  border-radius: 2px;
  display: flex;
  flex: 1;
  flex-direction: column;
  .form-chart-container {
    padding: 15px;

    // 查询条件表单
    .form-container {
      .ant-form-inline {
        gap: 20px;
      }
      .custom-capacity-search {
        button {
          border-left: none;
        }
      }
      display: flex;
      justify-content: flex-end;
      .ant-picker.ant-picker-range {
        border-color: #e5e7eb !important;
        border-radius: 2px;
        box-sizing: border-box;
        border: 1px solid #e5e7eb;
      }
    }
    // 图表统计
    .chart-container {
      display: flex;
      justify-content: space-around;
      margin-top: 15px;
      height: 180px;
      border-radius: 2px;
      box-sizing: border-box;
      border: 1px solid #e3e3e3;
      display: flex;
      .project-type-circle-container {
        position: relative;
        width: 33%;
        display: flex;
        text-align: center;
        .project-name {
          position: relative;
          top: -43%;
          left: 0;
          // transform: translate(-50%, -50%);
        }
      }
    }
  }
  // 表格
  .table-container {
    // padding: 0 0 50px 0;
    height: 450x;
    width: 100%;
    margin: auto;
    margin-top: 0;
    box-shadow: none;
    .custom-table {
      padding-left: 15px;
      padding-right: 15px;
      .operation {
        .button:first-child {
          .extend {
            left: 0;
          }
        }
        .button:first-child:hover {
          .extend {
            max-width: 110px;
            width: 60px;
          }
        }
      }
    }
    .custom-pagination {
      margin-top: 20px;
    }
  }
}
// 产能人效-详情页
.efficiency-detail-container {
  background: white;
  width: 100%;
  // min-height: 100%;
  padding: 15px;
  padding-bottom: 20px;
  .custom-tab {
    margin: none;
  }
  .tab-box {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;

    .tab-list {
      width: 80px;
      margin-right: 30px;
      cursor: pointer;
      font-size: 16px;
      font-weight: 500;
      padding-bottom: 10px;
      border-bottom: 3px solid #fff;
      position: relative;
      text-align: center;
      &.tab-active {
        border-bottom: 3px solid #1c64f2;
        &::after {
          pointer-events: none;
          position: absolute;
          content: "";
          border-style: solid;
          -webkit-transform: translateY(6px);
          transform: translateY(6px);
          opacity: 1;
          left: calc(50% - 5px);
          bottom: -1px;
          border-width: 6px 6px 0 6px;
          border-color: #1c64f2 transparent transparent transparent;
        }
      }
    }
  }
}

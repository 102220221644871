$primaryColor: #1fa6d7;
$fontColor1: #101820;
$grayBackground: #f7f8fa;

.transfer-tree {
 display: flex;
 border: 1px solid #E5E7EB;
 justify-content: flex-start;
 height: 310px;
flex: 1;
 border-radius: 2px;

 .left-tree {
  width: 50%;
  border-right: 1px solid #E5E7EB;
  padding: 10px 15px;

  .ant-select {
   margin: 0 10px;
   width: 140px;
  }

  .ant-input-group-wrapper {
   height: 32px;
   width: 238px;
   cursor: pointer;

   .ant-input-affix-wrapper {
    border-radius: 2px 0 0 2px !important;
    height: 32px;
   }

   .ant-input-group-addon {
    .ant-btn {
     border-left: none;
     border-radius: 0 2px 2px 0;

     &:hover {
      border: 1px solid #4096ff;
     }
    }
   }
  }

  .ant-btn-icon {
   color: #111928;
  }

  .ant-tree {
   height: 250px;
   overflow-y: auto;
   margin-top: 15px;
   padding-right: 10px;
   .ant-tree-switcher {
    position: absolute;
    right: 0;
   }

   .ant-tree-treenode {
    padding: 0 0 12px 0;
   }
  }
 }

 .right-choose {
  padding: 15px 0 15px 15px;
  color: #1F2A37;
  flex: 1;

  .checked-num {
   font-size: 14px;
  }

  .show-member {
   margin-top: 20px;
   font-size: 14px;
   height: 250px;
   overflow-y: auto;

   .member {
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
    margin-right: 15px;

    .icon-style {
     cursor: pointer;
    }
   }
  }
 }
}
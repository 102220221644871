$primaryColor: #1fa6d7;
$fontColor1: #101820;
$grayBackground: #f7f8fa;

.operate-button {
  color: #3f83f8;
  position: relative;
  height: 25px;
  cursor: pointer;
  margin-right: 10px;
  .text{
    white-space: nowrap;
    // font-size: 14px !important;
  }

  // .extend {
  //   max-width: 16px;
  //   transition: all ease-in-out 0.5s;
  //   position: absolute;
  //   overflow: hidden;
  //   white-space: nowrap;
  //   right: 0;
  //   top: 0;

  //   .text {
  //     margin-left: 4px;
  //     font-size: 14px;
  //     font-weight: normal;
  //     line-height: 16.38px;
  //     letter-spacing: 0px;
  //     color: #1c64f2;
  //   }
  // }
}
// .button:hover {
//   color: #3f83f8;
//   .extend {
//     max-width: 110px;
//     z-index: 1000;
//     border-radius: 20px;
//     background: #ebf5ff;
//     padding: 0px 8px 0px 5px;
//   }
// }

$primaryColor: #1fa6d7;
$fontColor1: #101820;
$grayBackground: #f7f8fa;

.SOPManagement {
 width: 100%;
 // min-height: 100%;
 background-color: #fff;
 padding: 15px;

 .sop_head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
 }

 .operation {
  .button:first-child {
   .extend {
    left: 0;
   }
  }

  .button:first-child:hover {
   .extend {
    max-width: 110px;
    width: 60px;
   }
  }
 }

 .basic_tabs {
  width: 520px;
 }

 .dnd_first_row {
  background: #EBF5FF;
 }

 .history_list {
  .list_head {
   margin-bottom: 16px;

   .list_name {
    font-size: #111928;
    margin-left: 4px;
    font-size: 16px;
   }
  }

  .list_content {
   display: flex;
   align-items: center;
   font-size: 12px;

   .content_time {
    width: 140px;
   }

   .content_img {
    width: 8px;
   }

   .content_name {
    margin-left: 12px;
    margin-right: 6px;
   }

   .content_op {
    color: #3F83F8;
    margin-right: 2px;
   }
  }
 }

 .examine-tabs {

  .green,
  .red {
   padding: 5px 8px;
   background: #EDFFF8;
   color: #3DB86D;
   border-radius: 20px;
   font-size: 12px;
  }

  .red {
   background: #FFF1F0;
   color: #F5222D;
  }

 }

}
$primaryColor: #1fa6d7;
$fontColor1: #101820;
$grayBackground: #f7f8fa;

.my-examine {
 padding: 20px 15px;
 background: #fff;
 overflow: auto;
 box-shadow: 0px 3px 20px 0px rgba(6, 33, 74, 0.1);

 .tab-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;

  .tab-list {
   margin-right: 30px;
   cursor: pointer;
   font-size: 16px;
   font-weight: 500;
   padding-bottom: 10px;
   border-bottom: 3px solid #fff;
   position: relative;

   &.tab-active {
    border-bottom: 3px solid #1c64f2;

    &::after {
     pointer-events: none;
     position: absolute;
     content: "";
     border-style: solid;
     -webkit-transform: translateY(6px);
     transform: translateY(6px);
     opacity: 1;
     left: calc(50% - 6px);
     bottom: -1px;
     border-width: 6px 6px 0 6px;
     border-color: #1c64f2 transparent transparent transparent;
    }
   }
  }
 }

 .approval-pending {
  .form-container {
   display: flex;
   justify-content: flex-end;
   align-items: center;
   flex-wrap: wrap;

   .ant-select {
    margin: 0 10px;
    width: 130px;
    border-radius: 2px;

   }

   .ant-input-group-wrapper {
    height: 32px;
    width: 240px;
    cursor: pointer;

    .ant-input-affix-wrapper {
     border-radius: 2px 0 0 2px !important;
     height: 32px;
    }

    .ant-input-group-addon {
     .ant-btn {
      border-left: none;
      border-radius: 0 2px 2px 0;

      &:hover {
       border: 1px solid #4096ff;
      }
     }
    }
   }

   .staff-range-picker {
    border-color: #e5e7eb !important;
    border-radius: 2px;
    box-sizing: border-box;
    border: 1px solid #e5e7eb;
    width: 250px;
    margin-right: 10px;
   }


   .creation {
    display: flex;
    align-items: center;
    font-size: 14px;

    i {
     font-size: 14px;
     margin-left: 3px;
    }
   }
  }
  .green,
  .red {
   padding: 5px 8px;
   background: #EDFFF8;
   color: #3DB86D;
   border-radius: 20px;
   font-size: 12px;
  }

  .red {
   background: #FFF1F0;
   color: #F5222D;
  }
  .wenhao{
   margin-left: 8px;
   color: #9CA3AF;
   cursor: pointer;
  }
 }

 
}
$primaryColor: #1fa6d7;
$fontColor1: #101820;
$grayBackground: #f7f8fa;

.my-project {
 padding-bottom: 20px;
 .form-box {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .ant-select {
   margin: 0 10px;
   width: 108px;
  }

  .ant-input-group-wrapper {
   height: 32px;
   width: 354px;
   cursor: pointer;

   .ant-input-affix-wrapper {
    border-radius: 2px 0 0 2px !important;
    height: 32px;
   }

   .ant-input-group-addon {
    .ant-btn {
     border-left: none;
     border-radius: 0 2px 2px 0;

     &:hover {
      border: 1px solid #4096ff;
     }
    }
   }
  }
 }

 .main-box {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 20px;

  

  .ant-card {
   width: 19.5%;
   margin-right: .5%;
   border-radius: 2px;
   box-shadow: 0px 3px 20px 0px rgba(6, 33, 74, 0.1);
   cursor: pointer;
   margin-bottom: 10px;
   min-height: 180px;

   &:hover {
    transform: rotate(1deg);
    -webkit-transform: rotate(1deg);
    -moz-transform: rotate(1deg);
    -o-transform: rotate(1deg);
    -ms-transform: rotate(1deg);
    box-shadow: 0px 5px 20px 0px rgba(0, 98, 244, 0.2);
   }

   &:nth-child(5n) {
    margin-right: 0;
   }

   .ant-card-head {
    font-size: 14px;
    font-weight: normal;
    margin: 0 15px;
    padding: 0;
    min-height: 45px;
    color: #374151;
   }

   .ant-card-body {
    padding: 15px;

    .project-name {
     font-size: 14px;
     font-weight: 500;
     margin-bottom: 15px;
     overflow: hidden;
     white-space: nowrap;
     text-overflow: ellipsis;
    }

    .star-status {
     font-size: 12px;
     font-weight: normal;
     height: 15px;

     .iconfont {
      font-size: 12px;
     }
    }

    .progress-status {
     display: flex;
     justify-content: space-between;
     align-items: center;
     font-size: 12px;
     color: #4B5563;
    }
   }
  }
 }
 .ant-pagination{
  margin-top: 10px !important;
 }

 .no-roject {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 500px;
  color: #6B7280;

  img {
   width: 300px;
   margin-bottom: 10px;
  }
 }
}
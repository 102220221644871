$primaryColor: #1fa6d7;
$fontColor1: #101820;
$grayBackground: #f7f8fa;

.roleManagement{
    width: 100%;
    // min-height: 100%;
    background: #fff;
    padding: 15px;
    .operation{
        .button:first-child{
          .extend{
            left: 0;
          }
        }
        .button:first-child:hover {
          .extend {
            max-width: 110px;
            width: 60px;
          }
        }
      }
    .role_add{
        margin-bottom: 15px;
        .add_icon{
            font-size: 14px;
        }
    }
    // .role_table{
        
    // }
    .role_page{
        // margin-top: 10px;
        text-align: right;
    }
}
$primaryColor: #1fa6d7;
$fontColor1: #101820;
$grayBackground: #f7f8fa;

.resizeTable {
 .react-resizable {
  position: relative;
  background-clip: padding-box;
  user-select: none;
 }

 // 防止默认出现横向滚动条
 .ant-table-content>table {
  min-width: calc(100% - 5px) !important;
 }
 .ant-table-fixed-column {
  .ant-table-thead {
    box-shadow: none;
    .ant-table-cell {
      border: 1px solid #d9dce1;
    }
  }
}

 .ant-table-thead {
  width: 100%;
  box-sizing: border-box;
  border-collapse: separate !important;
  height: 44px;
  overflow: inherit !important;
  box-shadow: -1px 0px 0px 0px #e3e3e3, 1px 0px 0px 0px #e3e3e3,
   0px -1px 0px 0px #e3e3e3, 0px 1px 0px 0px #e3e3e3;
  border: 1px solid #d9dce1;
  .ant-table-cell {
   border-right: 1px solid #d9dce1;
   border-radius: 1px !important;
   background: #f3f4f6;
   color: #111928;
   font-size: 16px;
   font-weight: normal;
   line-height: 24px;
   letter-spacing: 0px;
  }
 }

 .react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  background-image: none;
  z-index: 1;
 }
}
$primaryColor: #1fa6d7;
$fontColor1: #101820;
$grayBackground: #f7f8fa;

.PersonnelManagement {
 width: 100%;
 background: #fff;
 padding: 15px;

 .personnel-top {
  display: flex;
  justify-content: space-between;

  .personnel-left {
   display: flex;
   align-items: center;
   justify-content: flex-start;

   .ant-btn {
    margin-right: 15px;

    .add_icon {
     font-size: 12px;
     margin-left: 4px;
    }
   }
  }
 }
 .custom-table{
  margin-top: 15px;
 
 }
}
$primaryColor: #1fa6d7;
$fontColor1: #101820;
$grayBackground: #f7f8fa;

.product-page {
  padding: 15px;
  background: #fff;
  .form-container {
    display: flex;
    justify-content: space-between;
  }
  .blue-text {
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
    letter-spacing: -0.01px;
    text-decoration: underline;
    color: #1c64f2;
    cursor: pointer;
  }
  .operate{
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
    letter-spacing: -0.01px;
    color: #1c64f2;
    cursor: pointer;
  }
  .table-container {
    padding: 15px 0 0 0;
  }
  .creation {
    display: flex;
    align-items: center;
    font-size: 14px;

    i {
      font-size: 14px;
      margin-left: 3px;
    }
  }
}
.product-detail{
  padding: 15px;
  background: #fff;
  .schedule {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    color: #111928;
    // margin-bottom: 10px;
    .iconfont {
      color: #3f83f8;
      margin-right: 5px;
    }
    .tab-box {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 15px;

      .tab-list {
        margin-right: 30px;
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 10px;
        border-bottom: 3px solid #fff;
        position: relative;
        text-align: center;
        &.tab-active {
          border-bottom: 3px solid #1c64f2;
          &::after {
            pointer-events: none;
            position: absolute;
            content: "";
            border-style: solid;
            -webkit-transform: translateY(6px);
            transform: translateY(6px);
            opacity: 1;
            left: calc(50% - 5px);
            bottom: -1px;
            border-width: 6px 6px 0 6px;
            border-color: #1c64f2 transparent transparent transparent;
          }
        }
      }
    }
    .ant-tabs-nav {
      .ant-tabs-ink-bar {
        height: 3px !important;
      }
      .ant-tabs-nav-list {
        .ant-tabs-tab-btn {
          font-size: 16px;
          font-weight: normal;
          line-height: 24px;
          letter-spacing: 0px;
          color: #374151;
        }
      }
      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0px;
          color: #111928 !important;
          text-shadow: none;
        }
      }
    }
    .ant-tabs-nav::before {
      border: none;
    }
  }
  .base-info{
    .row{
      display: flex;
      width: 460px;
      height: 45px;
      border: 1px solid #E3E3E3;
      border-bottom: none;
      .col-name{
        width: 110px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-weight: normal;
        line-height: 22px;
        display: flex;
        align-items: center;
        letter-spacing: -0.01px;
        color: #374151;
        background: #F9FAFB;
        border-right: 1px solid #E3E3E3;
        .iconfont{
          transform: scale(0.4);
          color: #FF3B10;
          margin-right: 4px;
        }
      }
      .col-value{
        display: flex;
        align-items: center;
        width: 350px;
        padding-left:16px;
        font-size: 14px;
        font-weight: normal;
        line-height: 22px;
        display: flex;
        align-items: center;
        letter-spacing: -0.01px;
        color: #4B5563;
      }
    }
    .row:last-child{
      border-bottom: 1px solid #E3E3E3;
    }
  }
  .placement-posture{
    .special-operation {
      cursor: pointer;
      color: #3f83f8;
      .iconfont {
        font-size: 16px;
      }
    }
  }
  .foot{
    margin-top: 20px;
    button{
      margin-right: 5px;
    }
    .iconfont{
      font-size: 13px !important;
      margin-right: 3px;
    }
  }
  
}

$primaryColor: #1fa6d7;
$fontColor1: #101820;
$grayBackground: #f7f8fa;

.mobile-exam{
  height: calc(100vh);
  width: 100%;
  overflow: hidden;
  position: relative;
  .header-time {
    width: 100%;
    height: 123px;
    background: #0256F8;
    padding: 12px 17px 0;
    display: flex;
    justify-content: flex-end;
    color: #FFFFFF;
    .time {
      font-family: "Source Han Sans";
      font-size: 15px;
      line-height: normal;
      margin-left: 6px;
    }
  }
  .exam-content {
    background-color: #F3F4F6;
    position: relative;
    padding: 0 17px;
    height: calc(100vh - 123px - 25px);
    .exam-pager {
      position: absolute;
      top: -77px;
      left: 50%;
      transform: translate(-50%);
      width: 345px;
      height: calc(100vh - 46px - 42px - 25px);
      border-radius: 5px;
      background-color: #fff;
      overflow-y: auto;
      padding: 15px;
      .exam-title {
        font-size: 16px;
        font-weight: bold;
        line-height: normal;
        letter-spacing: 1px;
        color: #1F2A37;
      }
      .exam-explain {
        font-size: 13px;
        font-weight: normal;
        line-height: 22px;
        letter-spacing: 1px;
        color: #9CA3AF;
        margin: 8px 0 15px;
      }
    }
  }
  .submit {
    position: absolute;
    bottom: 10px;
    left: 0;
    width: 100%;
    padding: 0 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      width: 100%;
      height: 42px;
    }
  }
}
.exam-list {
  margin: 27px 15px 10px ;
  overflow: hidden;
  .title {
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 0em;
    color: #1F2A37;
    margin-bottom: 15px;
    text-align: center;
  }
  .list {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 80px);
    overflow-y: auto;
    .exam-item {
      width: 100%;
      height: 174px;
      background-color: #fff;
      padding: 12px;
      margin-bottom: 12px;
      .item-top {
        display: flex;
        padding-bottom: 12px;
        border-bottom: 1px solid #F3F4F6;
        .top-left {
          position: relative;
          margin-right: 12px;
          .exam-image {
            width: 104px;
            height: 78px;
            border-radius: 5px;
          }
          .exam-status {
            position: absolute;
            top: 0;
            right: 0;
            width: 40px;
            height: 17px;
            font-size: 10px;
            font-weight: 500;
            line-height: normal;
            letter-spacing: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #FFD6D6;
            color: #EB5757;
            border-top-right-radius: 5px;
            border-bottom-left-radius: 5px;
          }
        }
        .top-right {
          padding-top: 4px;
          font-size: 12px;
          font-weight: normal;
          line-height: normal;
          letter-spacing: 0px;
          .project-name {
            font-size: 15px;
            font-weight: bold;
            line-height: normal;
            letter-spacing: 0px;
            color: #374151;
          }
          .label {
            color: #9CA3AF
          }
          .wrapper {
            color: #374151
          }
        }
      }
      .exam-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 12px;
        .label {
          font-size: 12px;
          font-weight: normal;
          line-height: normal;
          letter-spacing: 0px;
          color: #9CA3AF;
          margin-bottom: 4px;
        }
        .wrapper {
          font-family: "MiSans";
          font-size: 20px;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.01px;
          color: #EB5757;
        }
      }
    }
    .exam-item:last-child {
      margin-bottom: 0;
    }
  }
  .empty-list {
    display: flex;
    height: calc(100vh - 80px);
    overflow-y: auto;
    justify-content: center;
    align-items: center;
    .empty-image {
      width: 250.57px;
      height: 174px;
    }
  }
}
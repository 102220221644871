$primaryColor: #1fa6d7;
$fontColor1: #101820;
$grayBackground: #f7f8fa;

.exam-bank {
  background-color: #fff;
  height: 100%;
  padding: 15px;
  .custom-table{
    margin-top: 15px;
   .subject-name {
      color: #3F83F8;
      cursor: pointer;
   }
  }
  .sub-header {
    margin-bottom: 17px;
    display: flex;
    justify-content: space-between;
  }
  .sub-name {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    color: #1F2A37;
    margin-left: 8px;
  }
  .iconfont {
    cursor: pointer;
  }
  .footer-button {
    display: flex;
    margin-left: 80px;
    gap: 10px;
    .ant-btn-dangerous {
      background-color: #f5222d;
    }
    .iconfont {
      margin-right: 5px;
    }
  }
  .ant-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .search-line {
    display: flex;
    justify-content: space-between;
  }
  .ant-input-group-wrapper {
    height: 32px;
    cursor: pointer;
 
    .ant-input-affix-wrapper {
     border-radius: 2px 0 0 2px !important;
     height: 32px;
    }
 
    .ant-input-group-addon {
     .ant-btn {
      border-left: none;
      border-radius: 0 2px 2px 0;
 
      &:hover {
       border: 1px solid #4096ff;
      }
     }
    }
  }
  .ant-input-number-group {
    .ant-input-number-group-addon {
      border-radius: 2px !important;
    }
  }
  .ant-input-number-group-addon{
    background-color: #fff;
    border: none;
  }
  .ant-input-number-group >.ant-input-number:first-child {
    border-start-end-radius: 6px;
    border-end-end-radius: 6px;
  }
  .config-table {
    thead {
      background: #F3F4F6;
      border: 1px solid #D9DCE1;
      td,th {
        border: 1px solid #D9DCE1;
        padding: 10px;
        width: 164px;
        font-size: 16px;
        font-weight: normal;
        line-height: 24px;
        letter-spacing: 0px;
        color: #111928;
        text-align: left;
      }
    }
    tr {
      border: 1px solid #D9DCE1;
      td {
        padding: 12px;
        font-size: 16px;
        font-weight: normal;
        letter-spacing: 0px;
        color: #111928;
      }
      .ant-form-item {
        margin-bottom: 0px;
      }
    }
  }
}
.modal-table {
  border: 1px solid #E5E7EB !important;
  .ant-table-cell {
    padding: 10px !important;
    line-height: 24px !important;
  }
}
.ant-modal-confirm-body-wrapper {
  .ant-modal-confirm-body {
    flex-wrap: nowrap !important;
  }
  .ant-modal-confirm-btns {
    display: flex;
    justify-content: flex-end;
    .ant-btn {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 5px;
}

$primaryColor: #1fa6d7;
$fontColor1: #101820;
$grayBackground: #f7f8fa;

// 工时页面
.work-hour-container {
  padding: 15px;
  background: #ffffff;
  .workhour-switch {
    height: 62px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    border-bottom: 1px solid #F3F4F6;
    .label {
      font-size: 14px;
      font-weight: normal;
      line-height: 22px;
      display: flex;
      align-items: center;
      letter-spacing: -0.01px;
      color: #111928;
    }
    .rangeTime {
      margin-left: 15px;
      border: 1px solid #E5E7EB !important;
      width: 282px;
    }
  }
  .header-container {
    display: flex;
    justify-content: space-between;
    .button-group {
      display: grid;
      grid-template-columns: repeat(2,1fr);
      column-gap: 10px;
    }
    .staff-input,
    .staff-range-picker {
      border-color: #e5e7eb !important;
      border-radius: 2px;
      box-sizing: border-box;
      border: 1px solid #e5e7eb;
    }
    .button-export {
      display: flex;
      align-items: center;
      justify-items: center;
      gap: 2px;
      border-color: #1c64f2;
      background: #1c64f2;
      color: #ffffff;
      .span {
        font-family: "思源黑体 CN";
        font-size: 14px;
        line-height: 22px;
        color: #fff;
      }
      i {
        height: 24px;
      }
    }
    .finance {
      background: #fff;
      border-color: #D1D5DB;
      color: #374151;
    }
  }
  .custom-table {
    margin-top: 16px;
  }
  .ant-form-item {
    margin-left: 15px;
  }
  .btn-query {
    border-color: #1c64f2;
    background: #1c64f2;
    color: #ffffff;
    font-size: 12px;
    font-family: "Source Han Sans CN";
  }
}
// 工时明细弹框
.work-hour-detail-modal {
  height: 556px;
  .ant-modal-footer {
    margin-top: 30px;
    i {
      font-size: 12px;
    }
  }
  .ant-table {
    max-height: 500px;
    overflow-y: auto;
  }
  .ant-modal-body {
    hr {
      margin: 15px -20px 0 -20px;
      color: "#E5E7EB";
      opacity: 0.2;
    }
    .header-area {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      button {
        display: inline;
        vertical-align: center;
      }
    }
  }
  .button-export {
    width: 80px;
  }
  .custom-table {
    margin-top: 15px;
  }
}

@font-face {
  font-family: "iconfont"; /* Project id 4108972 */
  src: url('iconfont.woff2?t=1726193347126') format('woff2'),
       url('iconfont.woff?t=1726193347126') format('woff'),
       url('iconfont.ttf?t=1726193347126') format('truetype'),
       url('iconfont.svg?t=1726193347126#iconfont') format('svg');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-tishi:before {
  content: "\e7b4";
}

.icon-zhengque:before {
  content: "\e7b1";
}

.icon-cuowu:before {
  content: "\e7b0";
}

.icon-miaobiao:before {
  content: "\e7a9";
}

.icon-kaoshishezhi:before {
  content: "\e7a8";
}

.icon-kaoshijilu:before {
  content: "\e7a5";
}

.icon-kaoshitiku:before {
  content: "\e7a7";
}

.icon-fajuanzi1:before {
  content: "\e7a4";
}

.icon-shanchu1:before {
  content: "\e7a3";
}

.icon-weixuanzhong:before {
  content: "\e7a1";
}

.icon-xuanzhong:before {
  content: "\e7a0";
}

.icon-shijian:before {
  content: "\e784";
}

.icon-jiaojiexuanzhong:before {
  content: "\e783";
}

.icon-xinzeng:before {
  content: "\e782";
}

.icon-xiangmubiangenglvli:before {
  content: "\e781";
}

.icon-quanbujiaojie:before {
  content: "\e780";
}

.icon-rili:before {
  content: "\e77f";
}

.icon-xingxing:before {
  content: "\e6e4";
}

.icon-shenqingxiugai:before {
  content: "\e6e3";
}

.icon-left-icon:before {
  content: "\e6e2";
}

.icon-shanchu:before {
  content: "\e6d5";
}

.icon-zujianleixing:before {
  content: "\e6d4";
}

.icon-jin12geyuexiangmukaizhanqushi:before {
  content: "\e6d3";
}

.icon-tongji:before {
  content: "\e6d1";
}

.icon-xiangmuleixingtongji:before {
  content: "\e6d2";
}

.icon-a-zu92:before {
  content: "\e609";
}

.icon-a-zu94:before {
  content: "\e60a";
}

.icon-baocun:before {
  content: "\e6d0";
}

.icon-chuku:before {
  content: "\e6cc";
}

.icon-xia:before {
  content: "\e6cb";
}

.icon-shang:before {
  content: "\e6c9";
}

.icon-moren:before {
  content: "\e6ca";
}

.icon-a-tuichuxuanzhong:before {
  content: "\e946";
}

.icon-a-tuichumoren:before {
  content: "\e941";
}

.icon-close:before {
  content: "\e945";
}

.icon-a-IconsSolid:before {
  content: "\e8e4";
}

.icon-a-Property1tongyongProperty2zhankaizhuangtaiDefault:before {
  content: "\e84e";
}

.icon-a-Property1tongyongProperty2shouqizhuangtaiDefault:before {
  content: "\e851";
}

.icon-logout1:before {
  content: "\e608";
}

.icon-a-xiangmuguanlimoren:before {
  content: "\e93f";
}

.icon-a-shebeiguanlimoren:before {
  content: "\e940";
}

.icon-a-xiangmukongjianmoren:before {
  content: "\e942";
}

.icon-a-yunyingfenximoren:before {
  content: "\e943";
}

.icon-a-hezuozhongxinmoren:before {
  content: "\e944";
}

.icon-upload:before {
  content: "\e936";
}

.icon-x-circle:before {
  content: "\e937";
}

.icon-truck:before {
  content: "\e938";
}

.icon-users:before {
  content: "\e939";
}

.icon-user-group:before {
  content: "\e93a";
}

.icon-view-grid-add:before {
  content: "\e93b";
}

.icon-x:before {
  content: "\e93c";
}

.icon-user-remove:before {
  content: "\e93d";
}

.icon-trending-down:before {
  content: "\e93e";
}

.icon-adjustments:before {
  content: "\e895";
}

.icon-arrow-right:before {
  content: "\e896";
}

.icon-arrow-circle-up:before {
  content: "\e897";
}

.icon-arrow-narrow-right:before {
  content: "\e898";
}

.icon-arrow-down:before {
  content: "\e899";
}

.icon-arrow-circle-left:before {
  content: "\e89a";
}

.icon-arrow-circle-right:before {
  content: "\e89b";
}

.icon-arrow-narrow-left:before {
  content: "\e89c";
}

.icon-arrow-left:before {
  content: "\e89d";
}

.icon-archive:before {
  content: "\e89e";
}

.icon-arrow-up:before {
  content: "\e89f";
}

.icon-ban:before {
  content: "\e8a0";
}

.icon-arrow-circle-down:before {
  content: "\e8a1";
}

.icon-backspace:before {
  content: "\e8a2";
}

.icon-arrow-narrow-down:before {
  content: "\e8a3";
}

.icon-calendar:before {
  content: "\e8a4";
}

.icon-badge-check:before {
  content: "\e8a5";
}

.icon-annotation:before {
  content: "\e8a6";
}

.icon-bookmark:before {
  content: "\e8a7";
}

.icon-chevron-left:before {
  content: "\e8a8";
}

.icon-bookmark-alt:before {
  content: "\e8a9";
}

.icon-briefcase:before {
  content: "\e8aa";
}

.icon-chart-pie:before {
  content: "\e8ab";
}

.icon-chevron-down:before {
  content: "\e8ac";
}

.icon-check:before {
  content: "\e8ad";
}

.icon-arrow-narrow-up:before {
  content: "\e8ae";
}

.icon-bell:before {
  content: "\e8af";
}

.icon-clipboard-copy:before {
  content: "\e8b0";
}

.icon-chevron-double-down:before {
  content: "\e8b1";
}

.icon-check-circle:before {
  content: "\e8b2";
}

.icon-chevron-double-up:before {
  content: "\e8b3";
}

.icon-cloud-upload:before {
  content: "\e8b4";
}

.icon-cash:before {
  content: "\e8b5";
}

.icon-cloud-download:before {
  content: "\e8b6";
}

.icon-clipboard-check:before {
  content: "\e8b7";
}

.icon-chart-square-bar:before {
  content: "\e8b8";
}

.icon-cog:before {
  content: "\e8b9";
}

.icon-collection:before {
  content: "\e8ba";
}

.icon-credit-card:before {
  content: "\e8bb";
}

.icon-chart-bar:before {
  content: "\e8bc";
}

.icon-chevron-double-left:before {
  content: "\e8bd";
}

.icon-cube:before {
  content: "\e8be";
}

.icon-document-add:before {
  content: "\e8bf";
}

.icon-clock:before {
  content: "\e8c0";
}

.icon-cloud:before {
  content: "\e8c1";
}

.icon-document-search:before {
  content: "\e8c2";
}

.icon-document-duplicate:before {
  content: "\e8c3";
}

.icon-exclamation-circle:before {
  content: "\e8c4";
}

.icon-device-mobile:before {
  content: "\e8c5";
}

.icon-document-report:before {
  content: "\e8c6";
}

.icon-color-swatch:before {
  content: "\e8c7";
}

.icon-document-download:before {
  content: "\e8c8";
}

.icon-document-text:before {
  content: "\e8c9";
}

.icon-dots-circle-horizontal:before {
  content: "\e8ca";
}

.icon-chevron-up:before {
  content: "\e8cb";
}

.icon-eye-off:before {
  content: "\e8cc";
}

.icon-enter:before {
  content: "\e8cd";
}

.icon-folder-add:before {
  content: "\e8ce";
}

.icon-cube-transparent:before {
  content: "\e8cf";
}

.icon-duplicate:before {
  content: "\e8d0";
}

.icon-download:before {
  content: "\e8d1";
}

.icon-folder-open:before {
  content: "\e8d2";
}

.icon-key:before {
  content: "\e8d3";
}

.icon-dots-vertical:before {
  content: "\e8d4";
}

.icon-home:before {
  content: "\e8d5";
}

.icon-folder:before {
  content: "\e8d6";
}

.icon-lightning-bolt:before {
  content: "\e8d7";
}

.icon-inbox:before {
  content: "\e8d8";
}

.icon-lock-closed:before {
  content: "\e8d9";
}

.icon-external-link:before {
  content: "\e8da";
}

.icon-inbox-in:before {
  content: "\e8db";
}

.icon-fast-forward:before {
  content: "\e8dc";
}

.icon-globe:before {
  content: "\e8dd";
}

.icon-eye:before {
  content: "\e8de";
}

.icon-chevron-right:before {
  content: "\e8df";
}

.icon-filter:before {
  content: "\e8e0";
}

.icon-folder-remove:before {
  content: "\e8e1";
}

.icon-identification:before {
  content: "\e8e2";
}

.icon-desktop-computer:before {
  content: "\e8e3";
}

.icon-lock-open:before {
  content: "\e8e5";
}

.icon-mail-open:before {
  content: "\e8e6";
}

.icon-clipboard-list:before {
  content: "\e8e7";
}

.icon-location-marker:before {
  content: "\e8e8";
}

.icon-md-library:before {
  content: "\e8e9";
}

.icon-mail-1:before {
  content: "\e8ea";
}

.icon-chevron-double-right:before {
  content: "\e8eb";
}

.icon-dots-horizontal:before {
  content: "\e8ec";
}

.icon-flag:before {
  content: "\e8ed";
}

.icon-menu-alt-1:before {
  content: "\e8ee";
}

.icon-menu:before {
  content: "\e8ef";
}

.icon-map:before {
  content: "\e8f0";
}

.icon-icon-currency-dollar:before {
  content: "\e8f1";
}

.icon-exclamation:before {
  content: "\e8f4";
}

.icon-light-bulb:before {
  content: "\e8f5";
}

.icon-menu-alt-3:before {
  content: "\e8f6";
}

.icon-minus-circle:before {
  content: "\e8f7";
}

.icon-minus:before {
  content: "\e8f8";
}

.icon-menu-alt-4:before {
  content: "\e8f9";
}

.icon-hand:before {
  content: "\e8fa";
}

.icon-paper-clip:before {
  content: "\e8fb";
}

.icon-paper-airplane:before {
  content: "\e8fc";
}

.icon-folder-download:before {
  content: "\e8fd";
}

.icon-office-building:before {
  content: "\e8fe";
}

.icon-information-circle:before {
  content: "\e8ff";
}

.icon-pencil:before {
  content: "\e900";
}

.icon-plus-sm:before {
  content: "\e901";
}

.icon-minus-sm:before {
  content: "\e902";
}

.icon-presentation-chart-bar:before {
  content: "\e903";
}

.icon-pencil-alt:before {
  content: "\e904";
}

.icon-plus:before {
  content: "\e905";
}

.icon-printer:before {
  content: "\e906";
}

.icon-play:before {
  content: "\e907";
}

.icon-question-mark-circle:before {
  content: "\e908";
}

.icon-reply:before {
  content: "\e909";
}

.icon-puzzle:before {
  content: "\e90a";
}

.icon-menu-alt-2:before {
  content: "\e90b";
}

.icon-rss:before {
  content: "\e90c";
}

.icon-link:before {
  content: "\e90d";
}

.icon-rewind:before {
  content: "\e90e";
}

.icon-newspaper:before {
  content: "\e90f";
}

.icon-server:before {
  content: "\e910";
}

.icon-mail:before {
  content: "\e911";
}

.icon-shield-exclamation:before {
  content: "\e912";
}

.icon-sort-descending:before {
  content: "\e913";
}

.icon-selector:before {
  content: "\e914";
}

.icon-globe-alt:before {
  content: "\e915";
}

.icon-save:before {
  content: "\e916";
}

.icon-save2:before {
  content: "\e917";
}

.icon-share:before {
  content: "\e918";
}

.icon-star:before {
  content: "\e919";
}

.icon-presentation-chart-line:before {
  content: "\e91a";
}

.icon-sort-ascending:before {
  content: "\e91b";
}

.icon-pause:before {
  content: "\e91c";
}

.icon-microphone:before {
  content: "\e91d";
}

.icon-support:before {
  content: "\e91e";
}

.icon-table-1:before {
  content: "\e91f";
}

.icon-logout-1:before {
  content: "\e920";
}

.icon-refresh:before {
  content: "\e921";
}

.icon-ticket:before {
  content: "\e922";
}

.icon-template:before {
  content: "\e923";
}

.icon-switch-vertical:before {
  content: "\e924";
}

.icon-stop:before {
  content: "\e925";
}

.icon-terminal:before {
  content: "\e926";
}

.icon-search:before {
  content: "\e927";
}

.icon-save-as:before {
  content: "\e928";
}

.icon-table:before {
  content: "\e929";
}

.icon-plus-circle:before {
  content: "\e92a";
}

.icon-tag:before {
  content: "\e92b";
}

.icon-switch-horizontal:before {
  content: "\e92c";
}

.icon-user-circle:before {
  content: "\e92d";
}

.icon-trash:before {
  content: "\e92e";
}

.icon-view-boards:before {
  content: "\e92f";
}

.icon-trending-up:before {
  content: "\e930";
}

.icon-shield-check:before {
  content: "\e931";
}

.icon-user-add:before {
  content: "\e932";
}

.icon-speakerphone:before {
  content: "\e933";
}

.icon-view-grid:before {
  content: "\e934";
}

.icon-user:before {
  content: "\e935";
}


$primaryColor: #1fa6d7;
$fontColor1: #101820;
$grayBackground: #f7f8fa;

.project-management {
 .hidden-bottom {
  overflow: hidden;
 }

 .main-box {
  background: #fff;
  padding: 15px 15px 20px 15px;
  z-index: 10;
  box-shadow: 0px 3px 20px 0px rgba(6, 33, 74, 0.1);

  .form-container {
   display: flex;
   justify-content: space-between;
   align-items: center;

   .form-left {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .ant-select {
     margin: 0 10px;
     width: 108px;
    }

    .ant-input-group-wrapper {
     height: 32px;
     width: 354px;
     cursor: pointer;

     .ant-input-affix-wrapper {
      border-radius: 2px 0 0 2px !important;
      height: 32px;
     }

     .ant-input-group-addon {
      .ant-btn {
       border-left: none;
       border-radius: 0 2px 2px 0;

       &:hover {
        border: 1px solid #4096ff;
       }
      }
     }
    }

   }

   .creation {
    display: flex;
    align-items: center;
    font-size: 14px;

    i {
     font-size: 14px;
     margin-left: 3px;
    }
   }
  }

  .operation {
   display: flex;
   justify-content: flex-start;

   .button:first-child {
    .extend {
     left: 0;
     top: 0;
    }
   }

   .button:hover {
    .extend {
     width: 60px;
    }
   }
  }
  .forbid{
   color: #9CA3AF;
   cursor: pointer;
  }
 }
}

.procurementBudgetNumber{
  .ant-form-item-control-input {
    position: relative;
    .ant-input-number-group-wrapper::before{
      content: var(--before-content);
      display: var(--before-display);
      position: absolute;
      height: 32px;
      top: -38px;
      left: 0;
      padding: 5px 8px;
      font-size: 14px;
      font-weight: normal;
      line-height: 22px;
      letter-spacing: -0.01px;
      color: #fff;
      background: rgba(0, 0, 0, 0.5);
    }
    .ant-input-number-in-form-item::after {
      content: '';
      display: var(--before-display);
      position: absolute;
      top: -7px;
      left: 8px;
      border: 6px solid transparent;
      border-top-color: rgba(0, 0, 0, 0.5);
    }
  }
}

.budgetNumber, .procurementBudgetNumber {
  border-radius: 2px;
  .ant-input-number-group-addon{
    background-color: #F9FAFB;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
}

$primaryColor: #1fa6d7;
$fontColor1: #101820;
$grayBackground: #f7f8fa;

.question {
  margin-bottom: 22px;
  .question-title {
    font-size: 14px;
    font-weight: normal;
    line-height: normal;
    letter-spacing: 0px;
    color: #1F2A37;
    display: flex;
    .content {
      word-break: break-all;
      white-space: wrap;
      box-sizing: border-box;
    }
    .order{
      white-space: nowrap;
    }
  }
  .question-content {
    margin: 12px 0 0 14px;
    .ant-radio-group, .ant-checkbox-group {
      display: flex;
      flex-direction: column;
      word-break: break-all;
      .ant-radio-wrapper, .ant-checkbox-wrapper {
        margin-bottom: 10px;
      }
      .ant-radio-wrapper:last-child, .ant-checkbox-wrapper:last-child {
        margin-bottom: 0;
      }
      .ant-radio-disabled {
        font-size: 14px;
        font-weight: normal;
        line-height: normal;
        letter-spacing: 0px;
        color: #1F2A37 !important;
      }
      .answer-line {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: normal;
        line-height: 22px;
        letter-spacing: -0.01px;
        color: #1F2A37;
        .unchecked {
          display: inline-block;
          width: 14px;
          height: 14px;
          border: 1px solid #d9d9d9;
          border-radius: 4px;
        }
        .iconfont {
          width: 14px;
          height: 15px;
          box-sizing: border-box;
          font-size: 13px;
          color: #fff;
          display: flex;
          justify-content: space-around;
          align-items: center;
          border-radius: 4px;
        }
        .question-answer {
          padding: 0 8px;
        }
        .unradioed {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 14px;
          height: 14px;
          border: 1px solid #d9d9d9;
          border-radius: 50%;
        }
        .red {
          background-color: #EB5757;
          border: 1px solid #EB5757;
        }
        .green {
          background-color: #27AE60;
          border: 1px solid #27AE60;
        }
        .inner {
          display: inline-block;
          background-color: #fff;
          width: 6px;
          height: 6px;
          border-radius: 50%;
        }
      }
      
    }
  }
}
.question-page {
  position: relative;
  .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10
  }
}
// input[type="checkbox"]:checked {
//   outline: none;
//    !important;
//   background-color: red !important;
// }
$primaryColor: #1fa6d7;
$fontColor1: #101820;
$grayBackground: #f7f8fa;

.project-filemain-page {
  padding: 15px;
  background: #fff;
  .form-container {
    display: flex;
    justify-content: flex-end;
  }
  .overview-data {
    margin-left: auto;
    color: #86909c;
    display: flex;

    div {
      padding: 10px 16px;
      margin-left: 10px;
      background-color: #f7f8fa;
      border-radius: 4px;
    }
    span {
      color: $primaryColor;
    }
  }

  .blue-text {
    color: #1c64f2;
  }
}

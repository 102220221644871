$primaryColor: #1fa6d7;
$fontColor1: #101820;
$grayBackground: #f7f8fa;

.assembly-content {
  .table-container {
    margin-top: 10px;
    background: #fff;
    padding: 20px 15px;
  }
  .operation{
    .button:first-child{
      .extend{
        left: 0;
      }
    }
    .button:first-child:hover {
      .extend {
        max-width: 110px;
        width: 60px;
      }
    }
  }
}
.type-header {
  background: #fff;
  padding: 15px;
  box-shadow: 0px 3px 20px 0px rgba(6, 33, 74, 0.1),
    inset 0px 0px 0px 1px #ffffff;
  .title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    color: #374151;
    margin-bottom: 15px;
  }
  .types-content{
    display: flex;
    .types {
      max-width: calc(100vw - 162px );
      height: 135px;
      padding: 7px 8px 7px 7px;
      box-sizing: border-box;
      display: flex;
      overflow: auto;
      overflow-y: hidden;
      .type {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        position: relative;
        .type-item {
          background-image: url("../../assets/img/projectmanage/typebg.png");
          background-size: 100% 100%;
          padding: 15px;
          width: 120px;
          height: 87px;
          border-radius: 2px;
          border: 1px solid #e5e7eb;
          box-sizing: border-box;
          margin-left: 15px;
          .name {
            width: 88px;
            font-weight: normal;
            line-height: 22px;
            letter-spacing: -0.01px;
            color: #374151;
            margin-bottom: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-text-overflow: ellipsis;

          }
          .number {
            font-size: 24px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0px;
            color: #3d3d3d;
          }
        }
        .close {
          font-size: 20px;
          position: absolute;
          top: -10px;
          right: -10px;
          color: #9ca3af;
          z-index: 100;
        }
        .active {
          background-image: url("../../assets/img/projectmanage/typeactivebg.png");
          background-size: 100% 100%;
        }
        .triangle {
          margin: 12px 0 0 15px;
          width: 26px;
          border: 13.42px solid #1c64f2;
          border-color: #1c64f2 transparent transparent transparent;
        }
      }
    }
    .add-part{
      margin-top: 7px;
      overflow: hidden;
      display: flex;
      flex-direction: row-reverse;
      width: 132px;
      height: 87px;
      flex-shrink: 0;
      position: relative;
      right: 5px;
      box-sizing: border-box;
      .add-button {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 87px;
        border: 1px solid #e5e7eb;
        color: #1c64f2;
        .iconfont{
          font-size: 18px;
        }
      }
      .add-button:hover {
        color: #1c64f2;
        border: 1px solid #e5e7eb;
      }
    }
  }
  
}
.special-operation {
  cursor: pointer;
  color: #3f83f8;
  .iconfont {
    font-size: 16px;
  }
}
.iconplus {
  color: #374151;
  font-size: 20px !important;
}
.iconminus{
  font-size: 20px !important;
}

$primaryColor: #1fa6d7;
$fontColor1: #101820;
$grayBackground: #f7f8fa;

.tab-box-list {
  display: flex;
  height: 76px;
  box-sizing: border-box;
  overflow-y: hidden;
  gap: 10px;
  .tab-block {
    min-width: 60px;
    height: 76px;
    border-radius: 1px;
    background: #f3f4f6;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 6px;
    position: relative;
    cursor: pointer;
    .active-bg {
      width: 60px;
      height: 76px !important;
      background: #0256f8;
      position: absolute;
      opacity: 0.1;
      top: 0;
    }
    .date-item {
      text-align: center;
      display: flex;
      flex-direction: column;
      position: absolute;
      height: 76px;
      box-sizing: border-box;
      .item-month {
        font-family: "Source Han Sans CN";
        font-size: 12px;
        line-height: 20px;
        // display: flex;
        color: #374151;
        letter-spacing: -0.01px;
      }
      .item-date {
        font-family: "Source Han Sans CN";
        font-size: 18px;
        font-weight: bold;
        color: #374151;
        line-height: 26px;
        letter-spacing: 0;
      }
      .item-status {
        font-family: "Source Han Sans CN";
        font-size: 12px;
        color: #6b7280;
        line-height: 20px;
        letter-spacing: -0.01px;
      }
    }
    &.tab-block-active {
      .item-month,
      .item-date,
      .item-status {
        color: #0256f8;
      }
    }
  }
}

$primaryColor: #1fa6d7;
$fontColor1: #101820;
$grayBackground: #f7f8fa;

.management {
 padding: 20px 15px;
 background: #fff;
 overflow: auto;
 box-shadow: 0px 3px 20px 0px rgba(6, 33, 74, 0.1);

 .tab-box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;

  .tab-list {
   margin-right: 30px;
   cursor: pointer;
   font-size: 16px;
   font-weight: 500;
   padding-bottom: 10px;
   border-bottom: 3px solid #fff;
   position: relative;

   &.tab-active {
    border-bottom: 3px solid #1c64f2;

    &::after {
     pointer-events: none;
     position: absolute;
     content: "";
     border-style: solid;
     -webkit-transform: translateY(6px);
     transform: translateY(6px);
     opacity: 1;
     left: calc(50% - 6px);
     bottom: -1px;
     border-width: 6px 6px 0 6px;
     border-color: #1c64f2 transparent transparent transparent;
    }
   }
  }
 }

 .project-member {
  .form-container {
   display: flex;
   justify-content: space-between;
   align-items: center;

   .form-left {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .ant-select {
     margin: 0 10px;
     width: 108px;
    }

    .ant-input-group-wrapper {
     height: 32px;
     width: 354px;
     cursor: pointer;

     .ant-input-affix-wrapper {
      border-radius: 2px 0 0 2px !important;
      height: 32px;
     }

     .ant-input-group-addon {
      .ant-btn {
       border-left: none;
       border-radius: 0 2px 2px 0;

       &:hover {
        border: 1px solid #4096ff;
       }
      }
     }
    }
   }


   .creation {
    display: flex;
    align-items: center;
    font-size: 14px;

    i {
     font-size: 14px;
     margin-left: 3px;
    }
   }
  }

  .roleShow {
   padding: 2px 7px;
   border-radius: 10px;
   margin-left: 6px;
   font-size: 12px;

   &.blue {
    color: #1C64F2;
    background: #EBF5FF;
   }

   &.yellow {
    color: #F2994A;
    background: #FEF7F1;
   }
  }
 }

 .project-plan {
  padding-bottom: 50px;

  .ant-table-thead {
   font-weight: normal;
  }

  .ant-table-body {
   height: auto;
  }

  .ant-table-row {
   .ant-table-cell {
    padding: 0 16px;
    background: #ebf5ff;
    height: 44px !important;
   }
  }

  .ant-table-expanded-row {
   .ant-table-thead {
    height: 33px !important;

    .ant-table-cell {
     padding: 0 16px;
     font-weight: normal;
    }
   }

   .light-text {
    font-size: 14px;
    font-weight: normal;
    color: #9ca3af;
   }

   .ant-table-tbody {
    .ant-table-cell {
     padding: 16px;
     background: #fff !important;
    }
   }
  }

  .ant-table-expanded-row-fixed {
   padding: 0;
  }

  .wrap-text {
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 2;
   overflow: hidden;
  }

  .ant-select-selection-item {
   width: 100px;
  }

  .operation-box {
   display: flex;
   justify-content: space-between;
   color: #3F83F8;
   cursor: pointer;
  }

  .operation {
   display: flex;
   justify-content: flex-start;

   .operate-button {
    margin-right: 20px !important;
   }

   .button:hover {
    .extend {
     width: 60px;
    }
   }
  }

  .operate-button {
   margin-right: 0 !important;
  }
 }

 .confirm-box {
  height: 52px;
  line-height: 52px;
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 180px;
  right: 0;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.3);
  z-index: 11;

  .ant-btn {
   margin-left: 20px;

   .iconfont {
    font-size: 14px;
    margin-right: 5px;
   }
  }
 }

 .schedule {
  .time {
   display: flex;
   justify-content: flex-start;
   align-items: center;
  }


  .operation-box {
   .ant-btn {
    margin-right: 5px;

    .iconfont {
     font-size: 14px;
     margin-right: 5px;
    }
   }
  }
 }

 .basic-content {
  width: 700px;
  border: 1px solid #e3e3e3;

  .content_list {
   width: 100%;
   display: flex;
   border-bottom: 1px solid #e3e3e3;

   .list_label {
    width: 150px;
    line-height: 44px;
    text-align: right;
    padding-right: 16px;
    border-right: 1px solid #e3e3e3;
    background: #f9fafb;
    color: #374151;

    // vertical-align: middle;
    .label_red {
     color: #ff3b10;
     margin-right: 4px;
     vertical-align: middle;
    }
   }

   .list_mid {
    width: 550px;
    padding: 11px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #4b5563;
    word-break: break-all;

    .mid_erp {
     padding: 4px 8px;
     border: 1px solid #3f83f8;
     color: #3f83f8;
    }
   }
   .cost_red {
    color: #E73737;
    }
  }

  .content_list:last-child {
   border-bottom: none;
  }
 }

 // 工时审批
 .work-hour-approve-container {

  .task {
   color: #3f83f8;
   cursor: pointer;
  }

  // 提示
  .approve-tip {
   font-family: "Source Han Sans CN";
   font-size: 12px;
   color: #e73737;
  }

  // tab 标签容器
  .tab-date-container {
   padding-top: 15px;
   min-width: 76px;
  }

  .table-container {
   padding-top: 10px;
   width: 600px;

   .work-hour-approve-table {
    .ant-table-container {
     ::-webkit-scrollbar {
      width: 2px !important;
     }
    }

    .ant-table-thead {
     .ant-table-cell {
      border: 1px solid #e5e7eb;
      background: #f3f4f6;
     }
    }

    .task-select-tip {
     font-family: "Source Han Sans CN";
     font-size: 12px;
     color: #9ca3af;
    }

    .today-work-hour {
     margin: -16px;
     text-align: center;
     display: flex;
     flex-direction: column;
     justify-content: space-around;
     gap: 0;

     .today-work-hour-content {
      line-height: 40px;
      text-align: center;

      .work-hour-and-name {
       text-overflow: ellipsis;
       white-space: nowrap;
       overflow: hidden;
       width: 90%;
       margin: auto;
       cursor: pointer;
      }
     }

     .divider {
      border-top: 1px solid #ccc;
      opacity: 0.3;
     }
    }

    .ant-table-summary {
     background: #f3f4f6;

     .ant-table-cell {
      border: 1px solid #e5e7eb;
      text-align: center;
     }

     .ant-table-cell:first-child {
      text-align: left;
     }
    }
   }
  }

  // 撤回审批/审批通过
  .btn-container {
   height: 70px;
   display: flex;
   align-items: center;
   gap: 5px;

   Button {
    line-height: 20px;
    font-family: "Source Han Sans CN";
    font-size: 14px;
   }

   .btn-withdraw {
    color: #111928;
   }

   .btn-succcess {
    color: #ffffff;
    background: #1c64f2;
   }

   .btn-success-grey {
    color: #ffffff;
    cursor: not-allowed;
    background: grey;
   }
  }

  // 任务选择
  .ant-select-selector {
   min-width: 100px;
   background: none;
   border: none !important;
   box-shadow: none !important;
   padding-left: 0;
  }

  .operation-grey {
   cursor: not-allowed;
   color: grey;
  }
 }

 //工时统计
 .project-workhour {
  .form-container {
   display: flex;
   justify-content: space-between;

   .creation {
    display: flex;
    align-items: center;
   }
  }

  .form-left {
   .select {
    margin-right: 12px;
    width: 138px;
   }

   .range {
    margin-right: 12px;
    border: 1px solid #e5e7eb !important;
   }

   .search {
    color: #1C64F2;
    border: 1px solid #1C64F2;
   }
  }

  .count {
   color: #3F83F8;
   cursor: pointer;
  }

  .totle-hour {
   width: 100%;
   margin-top: 15px;
   padding: 7px 11px;
   background: rgba(2, 86, 248, 0.1);
   display: flex;

   .text {
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
    display: flex;
    align-items: center;
    letter-spacing: -0.01px;
    font-variation-settings: "opsz" auto;
    color: #374151;
    margin-left: 6px;
   }
  }
 }
}

.statistics-modal {
 .ant-table {
  max-height: 500px;
  overflow-y: auto;
 }

 .ant-modal-body {
  hr {
   margin: 15px -20px 0 -20px;
   color: "#E5E7EB";
   opacity: 0.2;
  }

  .header-area {
   display: flex;
   align-items: center;
   justify-content: space-between;
   margin-top: 20px;

   button {
    display: inline;
    vertical-align: center;
   }
  }
 }
}

//项目考试
.project-exam {
 .form-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .form-left {
   display: flex;
   justify-content: flex-end;
   align-items: center;

   .ant-select {
    margin: 0 10px;
    width: 140px;
   }

   .ant-input-group-wrapper {
    height: 32px;
    width: 354px;
    cursor: pointer;

    .ant-input-affix-wrapper {
     border-radius: 2px 0 0 2px !important;
     height: 32px;
    }

    .ant-input-group-addon {
     .ant-btn {
      border-left: none;
      border-radius: 0 2px 2px 0;

      &:hover {
       border: 1px solid #4096ff;
      }
     }
    }
   }

  }


  .creation {
   display: flex;
   align-items: center;
   font-size: 14px;

   i {
    font-size: 14px;
    margin-left: 3px;
   }
  }
 }


}

.sendOutExam {
 .choose-member {
  display: flex;
  justify-content: flex-start;

  &::before {
   display: inline-block;
   margin-inline-end: 4px;
   color: #ff4d4f;
   font-size: 14px;
   font-family: SimSun, sans-serif;
   line-height: 1.6;
   content: "*";
  }
 }

 .custom-tip {
  margin-left: 110px;
  margin-top: 5px;
  color: #ff4d4f;
 }
}

//答题明细
.answer-detail {
 .tab-box {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  overflow-x: auto;
  width: 660px;
  padding-bottom: 10px;

  .tab-list {
   margin-right: 30px;
   cursor: pointer;
   font-size: 16px;
   font-weight: 500;
   border-bottom: 3px solid #fff;
   position: relative;
   flex: 0 0 auto;

   &.tab-active {
    border-bottom: 3px solid #1c64f2;

    &::after {
     pointer-events: none;
     position: absolute;
     content: "";
     border-style: solid;
     -webkit-transform: translateY(6px);
     transform: translateY(6px);
     opacity: 1;
     left: calc(50% - 6px);
     bottom: -1px;
     border-width: 6px 6px 0 6px;
     border-color: #1c64f2 transparent transparent transparent;
    }
   }
  }
 }

 .pad-derive {

  .answer-score {
   border: 1px solid #E5E7EB;
   padding: 15px 0 0 15px;
   font-size: 14px;
   color: #6B7280;
   margin-bottom: 15px;

   p {
    margin-bottom: 15px;

    .score {
     font-size: 20px;
     color: #1F2A37;
    }
   }
  }
  .subject-name{
   color: #1F2A37;
   font-size: 16px;
   font-weight: bold;
   margin-bottom: 10px;
  }

  .answer-content {
   max-height: 400px;
   overflow-y: auto;
  }
 }
}
$primaryColor: #1fa6d7;
$fontColor1: #101820;
$grayBackground: #f7f8fa;

.exam-page {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .exam-content {
    width: 760px;
    padding: 20px 20px 30px;
    height: 100%;
    background: #FFFFFF;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.05);
    position: relative;
    .exam-paper {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      .exam-title {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        line-height: normal;
        letter-spacing: 1px;
        color: #1F2A37;
      }
      .exam-explain {
        font-size: 14px;
        font-weight: normal;
        line-height: 24px;
        letter-spacing: 1px;
        color: #6B7280;
        margin: 12px 0 24px;
      }
      .submit {
        margin-top: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        .submit-button {
          width: 280px;
          height: 40px;
          font-size: 14px;
          font-weight: normal;
          line-height: normal;
          letter-spacing: 1px;
        }
      }
    }
    .count-down {
      position: absolute;
      top: 0;
      right: -152px;
      width: 140px;
      height: 116px;
      background: #FFFFFF;
      box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.05);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .count-title {
        font-size: 14px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1px;
        color: #6B7280;
      }
      .count-time {
        font-family: "Source Han Sans";
        font-size: 18px;
        font-weight: bold;
        line-height: normal;
        letter-spacing: 0.05em;
        color: #F5222D;
        margin-top: 10px;
      }
    }
  }
}
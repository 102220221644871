$primaryColor: #1fa6d7;
$fontColor1: #101820;
$grayBackground: #f7f8fa;

.inventory-content{
  background: #fff;
  padding: 20px 16px;
  .table-container{
    box-shadow: none;
  }
  .form-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  .operation{
    .button:first-child{
      .extend{
        left: 0;
      }
    }
    .button:first-child:hover {
      .extend {
        max-width: 110px;
        width: 60px;
      }
    }
  }
}



$primaryColor: #1fa6d7;
$fontColor1: #101820;
$grayBackground: #f7f8fa;

// @media screen and (max-width: 375px ) {
//   .workhour-model {
//     font-size: 10px;
//   }
// }
.workhour-model {
  position: relative;
  height: 100vh;
  background: #1C64F2;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  .header {
    // background-color: red;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    height: 15%;
    align-items: center;
    .header-name {
      font-size: 16px;
      font-weight: bold;
      line-height: 20px;
      letter-spacing: -0.01px;
      font-variation-settings: "opsz" auto;
      font-feature-settings: "kern" on;
      color: #FFFFFF;
    }
    .header-img {
      height: 75.47%;
    }
  }
  .content {
    position: absolute;
    padding: 15px;
    top: 12%;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    .tip {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 12px;
      font-weight: normal;
      line-height: 20px;
      letter-spacing: -0.01px;
      color: #e73737;
      margin-bottom: 15px;
      .tip-icon {
        transform: scale(0.5);
      }
    }
    .head-row{
      padding: 0 10px;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0px;
      color: #374151;
      height: 46px;
      line-height: 46px;
      background: #F3F4F6;
      border: 1px solid #E3E3E3;
      margin-top: 13px;
      .head-col{
        padding-left: 10px;
        border-left: 1px solid #E3E3E3 ;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .task-list{
      height: 300px;
      overflow-y: auto;
      .row{
        border: 1px solid #E3E3E3;
        border-top: none;
        padding: 8px 10px;
        .task-name{
          font-size: 14px;
          font-weight: 500;
          line-height: 22px;
          display: flex;
          align-items: center;
          letter-spacing: -0.01px;
          color: #374151;
          word-wrap: break-word;
          word-break: break-all;
        }
        .project-name{
          font-size: 12px;
          font-weight: normal;
          line-height: 20px;
          display: flex;
          align-items: center;
          letter-spacing: -0.01px;
          color: #9CA3AF;
          word-wrap: break-word;
          word-break: break-all;
        }
        .col{
          padding-left: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          .iconfont {
            width: 26px;
            height: 24px;
            border-radius: 2px;
            border: 0.5px solid #D1D5DB;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .work-hour {
            margin: 0 10px;
          }
        }
      }
    }
    .confirm-button {
      width: 100%;
      margin-top: 15px;
      height: 46px;
    }
  }
}
$primaryColor: #1fa6d7;
$fontColor1: #101820;
$grayBackground: #f7f8fa;

.app {

 .silder-box {
  flex: 0 0 180px;
  width: 180px !important;
  min-width: 42px !important;
  max-width: 180px !important;

  &.ant-layout-sider-collapsed {
   flex: 0 0 42px;
   width: 42px !important;
   min-width: 42px !important;
   max-width: 42px !important;
  }

  .logo {
   height: 58px;
   display: flex;
   align-items: center;
   justify-content: center;

   img {
    width: 100%;
    height: 100%;
   }
  }

  .logo-text {
   display: flex;
   flex-direction: column;

   .logo-en {
    font-size: 7px;
    font-weight: normal;
    letter-spacing: 0.7px;
   }
  }
 }

 

 .site-layout {
  .site-layout-background {

   // display: flex;
   // justify-content: space-between;
   .head-left-container {
    display: flex;
    align-items: center;

    .my-breadcrumb {
     display: flex;

     .breadcrumb-name {
      color: #6b7280;
      cursor: pointer;
      font-size: 12px;
     }

     .breadcrumb-name:last-child {
      color: #111928;
     }

     .gang {
      color: #6b7280;
      margin: 0 10px;
     }

     .project-name {
      margin-left: 20px;
      font-size: 12px;
      color: #111928;
     }
    }

    .ant-breadcrumb-separator,
    .breadcrumb-style,
    .ant-breadcrumb-link {
     color: white;
    }
   }

   .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 60px;
    cursor: pointer;
    transition: color 0.3s;
   }

   .trigger:hover {
    color: #1890ff;
   }

   .user-msg {
    color: #fff;
    margin-right: 30px;
    padding: 4px 10px 4px 5px;
    float: right;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
    letter-spacing: -0.01px;
    color: #111928;

    .user-content {
     height: 40px;
     border-radius: 20px;
     display: flex;
     align-items: center;
     overflow: hidden;

     .user-name {
      margin-left: 8px;
      white-space: nowrap;
     }

     .user-logout {
      transition: all ease-in-out 1s;
      width: 0;
      display: flex;
      align-items: center;
      margin-left: 8px;
      white-space: nowrap;

      .iconfont {
       font-size: 18px;
      }
     }
    }
   }

   .user-msg:hover {
    .user-content {
     background: #ebf5ff;

     .user-logout {
      width: 82px;
     }
    }
   }
  }

  .content-container {
   height: 100%;
   overflow: auto;
  }
 }

 .ant-layout-sider {
  background: #111928;
 }

 .scrollbar {
  height: calc(100vh - 58px);
  background: url("~@/assets/img/side-bg.png") no-repeat;
  background-position: 50% 95%;
  background-size: 180px 180px;

  .system-menu {
   border-inline-end: none;
   border-radius: 0;
   background: #111928;

   .ant-menu-submenu-arrow {
    color: #fff;
   }

   // 选中时的样式
   .ant-menu-submenu-selected {
    .ant-menu-submenu-title {
     .ant-menu-title-content {
      color: #fff;
     }
    }

    .iconfont {
     color: #1c64f2;
    }
   }

   .ant-menu-sub {
    .ant-menu-item {
     margin-inline: 0;
     margin-block: 0;
     border-radius: 0 !important;
     width: 100%;
     height: 46px;

     &::before {
      width: 1px;
      height: 50px;
      content: "";
      background: #3e4a6d;
      position: absolute;
      left: 30px;
      top: 0;
     }

     &:hover {
      background: #1f2a37;
      color: #fff;
      border-radius: 0;

      &::before {
       background: #6d7caa !important;
       width: 1px;
       height: 50px;
       content: "";
       position: absolute;
       left: 30px;
       top: 0;
      }
     }
    }
   }

   // 子菜单选中样式
   .ant-menu-item-selected {
    background: #1c64f2;
    height: 46px;
    border-radius: 0;

    &::before {
     background: #6698fc !important;
     width: 1px;
     height: 50px;
     content: "";
     position: absolute;
     left: 30px;
     top: 0;
    }

    .ant-menu-title-content {
     color: #fff;
     font-size: 14px;
     font-weight: 500;
    }
   }

   // 父标题的样式
   .ant-menu-submenu-title {
    height: 50px;
    line-height: 50px;
    background: #111928;
    border-radius: 0;
    font-size: 16px;
    font-weight: 500;
    margin-inline: 0;
    margin-block: 0;
    width: 100%;

    &:hover {
     background: #1f2a37;
    }

    .ant-menu-title-content {
     font-weight: 500;
     color: #fff;
    }
   }

   // 所有标题的样式
   .ant-menu-title-content {
    font-size: 14px;
    letter-spacing: 1px;
    color: #d1d5db;
   }

   .iconfont {
    font-size: 20px;
    color: #707479;
   }

   .ant-menu-sub {
    background: #0a0f1a !important;

    .ant-menu-title-content {
     margin-left: 10px;
    }
   }
  }
 }

 .white-site-layout {

  .logo {
   width: 159px;
   margin: 12px 20px;
  }

  // .ant-layout-sider-children{
  //  background: #f5f5f5;
  // }
  .site-layout-background {

   // display: flex;
   // justify-content: space-between;
   .head-left-container {
    display: flex;
    align-items: center;

    .my-breadcrumb {
     display: flex;

     .breadcrumb-name {
      color: #6b7280;
      cursor: pointer;
      font-size: 12px;
     }

     .breadcrumb-name:last-child {
      color: #111928;
     }

     .gang {
      color: #6b7280;
      margin: 0 10px;
     }

     .project-name {
      margin-left: 20px;
      font-size: 12px;
      color: #111928;
     }
    }

    .ant-breadcrumb-separator,
    .breadcrumb-style,
    .ant-breadcrumb-link {
     color: white;
    }
   }

   .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 60px;
    cursor: pointer;
    transition: color 0.3s;
   }

   .trigger:hover {
    color: #1890ff;
   }

   .user-msg {
    color: #fff;
    margin-right: 30px;
    padding: 4px 10px 4px 5px;
    float: right;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
    letter-spacing: -0.01px;
    color: #111928;

    .user-content {
     height: 40px;
     border-radius: 20px;
     display: flex;
     align-items: center;
     overflow: hidden;

     .user-name {
      margin-left: 8px;
      white-space: nowrap;
     }

     .user-logout {
      transition: all ease-in-out 1s;
      width: 0;
      display: flex;
      align-items: center;
      margin-left: 8px;
      white-space: nowrap;

      .iconfont {
       font-size: 18px;
      }
     }
    }
   }

   .user-msg:hover {
    .user-content {
     background: #ebf5ff;

     .user-logout {
      width: 82px;
     }
    }
   }
  }

  .content-container {
   height: 100%;
   overflow: auto;
  }
 }

 ::-webkit-scrollbar-thumb {
  border-radius: 13px !important;
  box-shadow: inset 0 0 5px#C9CDD4 !important;
  opacity: 0.8 !important;
  background: #9ca3af !important;
 }

 ::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 6px !important;
  height: 6px !important;
 }

 ::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0) !important;
  border-radius: 5px !important;
  background: rgba(0, 0, 0, 0) !important;
 }
}

.ant-select-selector {
 // border-color: $grayBackground !important;
 // background-color: $grayBackground !important;
 border-radius: 2px !important;
}

.ant-input {
 // border-color: $grayBackground !important;
 // background-color: $grayBackground !important;
 border-radius: 2px !important;
}

.ant-picker.ant-picker-range {
 // background-color: $grayBackground !important;
 border-color: $grayBackground !important;
}

.ant-btn {
 // display: flex;
 // align-items: center;
 border-radius: 2px;
}

.ant-pagination-item-active {
 background: $primaryColor !important;

 a {
  color: #fff !important;
 }
}

.ant-input {
 // border-color: $grayBackground !important;
 // background-color: $grayBackground !important;
 border-radius: 2px !important;
}

.ant-input-affix-wrapper {
 border-radius: 2px !important;
}

.ant-picker {
 // border-color: $grayBackground !important;
 // background-color: $grayBackground !important;
 border-radius: 4px !important;
}

.ant-pagination {
 button {
  border-radius: 4px !important;
 }
}

.ant-pagination-item {
 border-radius: 4px;
}

.ant-pagination {
 .ant-select-selector {
  border-color: #d9d9d9 !important;
  background-color: initial !important;
 }
}

.ant-pagination-options-quick-jumper {
 input {
  border-radius: 4px;
  width: 60px;
 }
}

.hiden-option {
 display: none;
}

.ant-dropdown {
 .ant-dropdown-menu {
  background-color: #2c2c2c;
  border-radius: 4px;

  .ant-dropdown-menu-item {
   color: #ffffff;

   &:hover {
    background-color: #2c2c2c;
   }
  }
 }
}

.ant-btn {
 box-shadow: none;
}

.ant-btn-primary {
 box-shadow: none;
 background: #1c64f2;
}

.ant-tooltip-inner {
 color: #fff !important;
 font-size: 14px !important;
 background-color: rgba(0, 0, 0, 0.7) !important;
 box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.7) !important;
}

.ant-tooltip-arrow-content {
 --antd-arrow-background-color: rgba(0, 0, 0, 0.7) !important;
}
$primaryColor: #1fa6d7;
$fontColor1: #101820;
$grayBackground: #f7f8fa;

.projectOverview-page {
  height: 100%;
  .ant-picker.ant-picker-range {
    border-color: #d9d9d9 !important;
  }
  .top-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .top-container-left {
      background-image: url("../../assets/img/projectoverview/scheduleBg.png");
      background-size: 100% 100%;
      height: 210px;
      width: calc(100% - 500px);
      box-shadow: 0px 3px 20px 0px rgba(6, 33, 74, 0.1);
      margin-right: 10px;
      padding: 10px 15px;
      .container-left-item {
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #f3f4f6;
        .container-left-item-text {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0px;
          color: #111928;
          .iconfont {
            color: #1c64f2;
            font-size: 20px;
            margin-right: 7px;
          }
        }
        .container-right{
          display: flex;
          .gante {
            display: flex;
            align-items: center;
            border: 1px solid #e5e7eb;
            padding: 5px 16px;
            font-size: 14px;
            font-weight: normal;
            line-height: 22px;
            letter-spacing: -0.01px;
            color: #111928;
            background: #ffffff;
            cursor: pointer;
            .iconfont {
              color: #374151;
              font-size: 20px;
              margin-right: 10px;
            }
          }
          .gante:first-child {
            margin-right: 12px;
          }
        }
        
      }
      .echart-box {
        padding: 10px 0 25px 0;
        width: 100%;
        overflow-x: scroll;
        display: flex;
        justify-content: flex-start;
      }
    }
    .top-container-right {
      background: #ffffff;
      height: 210px;
      width: 500px;
      box-shadow: 0px 3px 20px 0px rgba(6, 33, 74, 0.1);
      padding: 0 15px 10px 15px;
      .container-right-item {
        padding: 15px 0px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #f3f4f6;
        .container-right-item-text {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0px;
          color: #111928;
          .iconfont {
            color: #1c64f2;
            font-size: 20px;
            margin-right: 7px;
          }
        }
        .more {
          font-size: 14px;
          font-weight: normal;
          line-height: 22px;
          letter-spacing: -0.01px;
          color: #111928;
          display: flex;
          align-items: center;
          cursor: pointer;
          .iconfont {
            color: #374151;
            font-size: 20px;
            margin-left: 5px;
          }
        }
      }
      .time-line {
        width: 100%;
        height: 130px;
        padding: 15px 0 0 0;
        overflow-y: auto;
        // display: flex;
        // justify-content: center;
        .event-item {
          display: flex;
          width: 100%;
          align-items: center;
          margin-bottom: 8px;
          .time {
            white-space: nowrap;
            width: 123px;
            font-size: 12px;
            font-weight: normal;
            line-height: 22px;
            letter-spacing: -0.01px;
            color: #111928;
          }
          .point-line {
            margin: 0 10px 0 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            white-space: nowrap;
            .point {
              width: 7px;
              height: 7px;
              border-radius: 50%;
              border: 2px solid #3f83f8;
            }
            .line {
              width: 1px;
              height: 23px;
              background: #3f83f8;
              margin-bottom: -26px;
            }
          }

          .man {
            font-size: 12px;
            font-weight: normal;
            line-height: 20px;
            letter-spacing: -0.01px;
            color: #111928;
            white-space: nowrap;
          }
          .over {
            flex: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-text-overflow: ellipsis;
          }
          .eventName {
            font-size: 12px;
            font-weight: normal;
            line-height: 20px;
            letter-spacing: -0.01px;
            color: #3f83f8;
            white-space: nowrap;
            margin: 0 5px;
          }
          .eventTask {
          }
          // justify-content: center;
        }
        .empty-img {
          width: 100%;
          height: 120px;
        }
      }
    }
  }
  .table-container {
    padding: 15px;
    background: #fff;
    margin-top: 10px;
    box-shadow: 0px 3px 20px 0px rgba(6, 33, 74, 0.1);
    .table-box {
      overflow-x: auto;
    }
  }
  .task-progress {
    display: flex;
    justify-content: flex-start;
    .percent {
      margin-right: 7px;
    }
  }
  .common-status {
    padding: 1px 8px;
    font-size: 12px;
    font-weight: normal;
    line-height: 20px;
    letter-spacing: -0.01px;
    border-radius: 11px;
    height: 22px;
    margin-right: 10px;
  }
  .in-progress {
    color: #3db86d;
    background: #edfff8;
  }
  .not-started {
    color: #16bdca;
    background: #e6fffb;
  }
  .completed {
    color: #1c64f2;
    background: #f0f5ff;
  }
  .in-progress-delayed {
    color: #f5222d;
    background: #fff1f0;
  }
  .not-started-delayed {
    color: #f3911a;
    background: #fff7e6;
  }

  .project-resume {
    padding: 15px;
    background: #fff;
    .form-container {
      display: flex;
      justify-content: space-between;
      .operate-part {
        display: flex;
        .external {
          background: '#fff';
          color: '#374151';
          display: "flex";
          border: 1px solid #E5E7EB;
        }
      }
      .anticon-search {
        color: #111928;
      }
      .ant-form-item-label {
        width: 70px !important;
      }
    }
    .finish-text {
      font-size: 14px;
      font-weight: normal;
      line-height: 22px;
      letter-spacing: -0.01px;
      color: #6B7280;
    }
  }

  .project-dynamics {
    padding: 15px;
    background: #fff;
    .project-top {
      margin-bottom: 20px;
      .iconfont {
        color: #3f83f8;
      }
      .project-name {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0px;
        color: #111928;
        margin-left: 5px;
      }
    }
    .search-line {
      display: flex;
      justify-content: space-between;
      .creation {
        display: flex;
        align-items: center;
        font-size: 14px;

        i {
          font-size: 14px;
          margin-left: 3px;
        }
      }
      :where(.css-dev-only-do-not-override-12jzuas).ant-form-item
        .ant-form-item-label
        > label {
        justify-content: flex-end !important;
        width: auto !important;
      }
    }
    .op {
      .op-i {
        font-family: 思源黑体 CN;
        width: 100%;
        font-size: 14px;
        font-weight: normal;
        line-height: 22px;
        letter-spacing: -0.01px;
        color: #111928;
        margin-bottom: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-text-overflow: ellipsis;
        .jindu {
          margin-right: 10px;
        }
        .shadow {
          width: 20px;
          height: 20px;
          position: relative;
          transform: rotateY(180deg);
          border-radius: 50%;
          mask: radial-gradient(transparent 7px, #000 2px);
          -webkit-mask: radial-gradient(transparent 7px, #000 2px);
        }
        .shadow::before {
          content: "";
          position: absolute;
          left: 48px;
          top: 0px;
          width: 5px;
          height: 5px;
          background: #ddff5c;
          border-radius: 50%;
        }
        .shadow::after {
          content: "";
          position: absolute;
          left: -1px;
          top: 44px;
          width: 8px;
          height: 8px;
          border-radius: 50%;
        }
      }
    }
  }

  .task-details {
    .task-details-top {
      display: flex;
      align-items: center;
      padding: 0 15px 0 15px;
      width: 100%;
      height: 144px;
      background: #fff;
      box-shadow: 0px 3px 20px 0px rgba(6, 33, 74, 0.1);
      .task-details-top-text {
        width: 90px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0px;
        color: #111928;
        margin-right: 10px;
      }
      .task-details-top-echart {
        overflow-x: auto;
        display: flex;
        height: 150px;
        flex: 1;
        .ProjectScheduleEcharts {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-right: -5px;
          height: 125px;
          .arrow-logo {
            width: 86px;
            margin-left: -10px;
            height: 32px;
          }
        }
      }
    }
    .task-details-bottom {
      margin-top: 10px;
      background: #fff;
      box-shadow: 0px 3px 20px 0px rgba(6, 33, 74, 0.1);
      .details-head {
        padding: 10px 10px 12px 10px;
      }
      .details-body {
        display: flex;
        padding: 0 10px 10px 10px;
        border-top: 1px solid #e5e7eb;
        .node-box {
          width: 8%;
          padding: 5px;
          border-right: 1px solid #e5e7eb;
          overflow-y: scroll;
          .node-text {
            font-family: 思源黑体 CN;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0px;
            color: #111928;
          }
          .node-item-box {
            margin-top: 15px;
            .node-item {
              display: flex;
              justify-content: center;
              margin-top: -3px;
              .progress-line {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                .line {
                  width: 1px;
                  height: 31px;
                  background: #e5e7eb;
                }
              }
              .jieduan-common {
                cursor: pointer;
                width: 116px;
                height: 24px;
                line-height: 22px;
                border-radius: 12px;
                padding: 1px 5px;
                margin-left: 10px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-text-overflow: ellipsis;
                text-align: center;
              }
              .jieduan-isMe {
                color: #111928;
                background: #ebf5ff;
              }
              .jieduan-notMe {
                color: #111928;
                background: #f9fafb;
              }
              .jieduan-isMe-active {
                color: #fff;
                background: #1c64f2;
              }
              .jieduan-notMe-active {
                border: 1px solid #1c64f2;
                color: #1c64f2;
                background: #fff;
              }
              .jieduan-isMe-active:hover {
                color: #111928;
                background: #c3ddfd;
              }
              .jieduan-notMe-active:hover {
                color: #111928;
                background: #e5e7eb;
              }
              .jieduan-isMe:hover {
                color: #111928;
                background: #c3ddfd;
              }
              .jieduan-notMe:hover {
                color: #111928;
                background: #e5e7eb;
              }
            }
          }
        }
        .task-details-part {
          padding: 9px 15px;
          width: calc(100% - 8%);
          .task-status-box {
            height: 35px;
            margin-bottom: 10px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            .task-status {
              display: flex;
              justify-content: flex-start;
              .task-name {
                margin-right: 10px;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                text-align: center;
                letter-spacing: 0px;
                color: #111928;
              }
              .task-status-text {
              }
            }
            .iconfont {
              margin-left: 4px;
            }
          }
        }
      }
      .form-row {
        display: flex;
        // align-items: center;
        border: 1px solid #e5e7eb;
        border-bottom: none;
        width: 100%;
        .form-title {
          width: 10%;
          border-right: 1px solid #e5e7eb;
          padding: 11px 15px;
          font-size: 14px;
          font-weight: normal;
          line-height: 22px;
          letter-spacing: -0.01px;
          color: #111928;
          background: #f3f4f6;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          flex-direction: column;
        }
        .form-title-resibility {
          width: 10%;
          border-right: 1px solid #e5e7eb;
          padding: 11px 15px;
          font-size: 14px;
          font-weight: normal;
          letter-spacing: -0.01px;
          color: #111928;
          height: fit-content;
          background: #f3f4f6;
        }
        .form-description {
          flex: 1;
          padding: 11px 10px;
          font-size: 14px;
          font-weight: normal;
          line-height: 22px;
          letter-spacing: -0.01px;
          color: #111928;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-text-overflow: ellipsis;
        }
        .form-Input {
          flex: 1;
          padding: 11px 10px;
          display: flex;
          flex-wrap: wrap;
          .file-item {
            cursor: pointer;
            display: flex;
            margin: 5px 0;
            align-items: center;
            margin-right: 15px;
            font-size: 14px;
            font-weight: normal;
            line-height: 22px;
            letter-spacing: -0.01px;
            color: #1c64f2;
            .iconfont {
              margin-right: 5px;
            }
          }
          .file-item-nourl {
            cursor: not-allowed;
            display: flex;
            margin: 5px 0;
            align-items: center;
            margin-right: 15px;
            font-size: 14px;
            font-weight: normal;
            line-height: 22px;
            letter-spacing: -0.01px;
            color: #9ca3af;
            .iconfont {
              margin-right: 5px;
            }
          }
        }
        .form-system {
          padding: 11px 10px;
          font-size: 14px;
          font-weight: normal;
          line-height: 22px;
          letter-spacing: -0.01px;
          color: #1c64f2;
          cursor: pointer;
        }

        .form-execution {
          padding: 11px 10px;
          font-size: 14px;
          font-weight: normal;
          line-height: 22px;
          letter-spacing: -0.01px;
          color: #1c64f2;
        }
        .form-content {
          width: 90%;
          padding: 11px 10px;
          .task-progress{
            display: flex;
            align-items: center;
            vertical-align: top;
          }
          .total-work-hour{
            margin-bottom: 5px;
          }
          .task-label{
            font-size: 14px;
            font-weight: normal;
            line-height: 20px;
            letter-spacing: -0.01px;
            color: #6B7280;
            white-space: nowrap;
            margin-bottom: 8px;
          }
          .text {
            width: 100%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-text-overflow: ellipsis;
            margin: 0 0 10px 0;
            font-size: 14px;
            font-weight: normal;
            line-height: 22px;
            letter-spacing: -0.01px;
            color: #111928;
          }
          .icon-calendar {
            cursor: pointer;
            color: #1c64f2;
            margin-left: 10px;
          }
          .jindu-btn {
            display: flex;
            Button {
              margin-right: 10px;
            }
          }
        }
      }
      .form-row:last-child {
        border-bottom: 1px solid #e5e7eb;
      }
      .deliverables-list {
        width: 100%;
        .deliverables-text {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0px;
          color: #111928;
          margin: 15px 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .ant-form-item-explain-error {
          white-space: pre-wrap;
        }
        .resources-box-all {
          overflow-x: scroll;
          display: flex;
          margin-top: 25px;
          padding-bottom: 10px;
          cursor: default;
          .resources-box {
            display: flex;
            margin-right: 25px;
            .man-logo {
              height: 45px;
              width: 45px;
              border-radius: 23px;
              margin-right: 8px;
            }
            .name-man-box {
              display: flex;
              .man-name {
                font-size: 14px;
                font-weight: normal;
                line-height: 22px;
                letter-spacing: -0.01px;
                color: #111928;
                margin-right: 5px;
              }
            }
            .duties {
              width: 120px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 12px;
              font-weight: normal;
              line-height: 20px;
              letter-spacing: -0.01px;
              color: #374151;
              margin-top: 5px;
            }
          }
        }

        .table-body:nth-child(2n + 1) {
          background: #f9fafb;
        }
        .table-body {
          border: 1px solid #e5e7eb;
          border-top: none;
          .ant-form-inline {
            gap: 0 !important;
          }
        }
        .table-header {
          width: 100%;
          display: flex;
          position: relative;
          .common-item-header {
            border: 1px solid #e5e7eb;
            background: #f3f4f6;
            font-size: 16px;
            font-weight: normal;
            line-height: 24px;
            letter-spacing: 0px;
            color: #111928;
            padding: 10px;
          }
          .common-item-body {
            font-size: 16px;
            font-weight: normal;
            line-height: 24px;
            letter-spacing: 0px;
            color: #111928;
            padding: 10px 8px;
            display: flex;
            align-items: center;
            .task-jindu {
              width: 100%;
              .text-detail {
                display: flex;
                width: 100%;
                align-items: center;
                .text {
                  width: 90%;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  -webkit-text-overflow: ellipsis;
                }
                .detail {
                  cursor: pointer;
                  color: #1c64f2;
                  font-size: 14px;
                  margin-left: 10px;
                }
              }
            }
          }

          .table-header-item1 {
            box-sizing: border-box;
            width: 15%;
            .taskName-toop {
              position: relative;
              width: 100%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-text-overflow: ellipsis;
              padding-right: 16px;
            }
          }
          .table-header-item2 {
            width: 15%;
            box-sizing: border-box;
            .ant-form-item-row {
              width: 100%;
            }
          }
          .table-header-item3 {
            width: 40%;
            box-sizing: border-box;
          }
          .table-header-item-long {
            width: 50%;
          }
          .table-header-item4 {
            width: 20%;
            box-sizing: border-box;
          }
          .table-header-item5 {
            width: 5%;
            box-sizing: border-box;
          }
          .add {
            width: 5%;
            cursor: pointer;
            .iconfont {
              font-size: 20px;
            }
          }
          .add-operation {
            width: 10%;
            justify-content: flex-end;
          }
          .private-css {
            position: absolute;
            height: 100%;
            top: 0;
            right: 0;
          }
        }
      }
    }
    .model-form-title {
      width: 120px;
    }
  }
  :where(.css-dev-only-do-not-override-12jzuas).ant-form-item
    .ant-form-item-label
    > label {
    justify-content: flex-end !important;
    width: 110px !important;
  }

  .task-details-list {
    padding: 15px;
    background: #fff;
    .tip {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-size: 12px;
      font-weight: normal;
      line-height: 20px;
      letter-spacing: -0.01px;
      color: #e73737;
      .tip-icon {
        transform: scale(0.5);
      }
    }
    .schedule {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0px;
      color: #111928;
      // margin-bottom: 10px;
      .iconfont {
        color: #3f83f8;
        margin-right: 5px;
      }
      .tab-box {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 15px;

        .tab-list {
          margin-right: 30px;
          cursor: pointer;
          font-size: 16px;
          font-weight: 500;
          padding-bottom: 10px;
          border-bottom: 3px solid #fff;
          position: relative;
          text-align: center;
          &.tab-active {
            border-bottom: 3px solid #1c64f2;
            &::after {
              pointer-events: none;
              position: absolute;
              content: "";
              border-style: solid;
              -webkit-transform: translateY(6px);
              transform: translateY(6px);
              opacity: 1;
              left: calc(50% - 5px);
              bottom: -1px;
              border-width: 6px 6px 0 6px;
              border-color: #1c64f2 transparent transparent transparent;
            }
          }
        }
      }
      .ant-tabs-nav {
        .ant-tabs-ink-bar {
          height: 3px !important;
        }
        .ant-tabs-nav-list {
          .ant-tabs-tab-btn {
            font-size: 16px;
            font-weight: normal;
            line-height: 24px;
            letter-spacing: 0px;
            color: #374151;
          }
        }
        .ant-tabs-tab-active {
          .ant-tabs-tab-btn {
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0px;
            color: #111928 !important;
            text-shadow: none;
          }
        }
      }
      .ant-tabs-nav::before {
        border: none;
      }
    }
    .icon-baocun {
      font-size: 13px;
      margin-right: 5px;
    }
  }

  .jindu-box {
    display: flex;
    align-items: center;
    .jindu-box-text {
      font-size: 14px;
      font-weight: normal;
      line-height: 22px;
      letter-spacing: -0.01px;
      color: #111928;
      margin-right: 7px;
    }
    Progress {
    }
  }

  .sanjiao {
    position: relative;
    transition: all 0.8s;
    margin-right: 10px;

    &:hover {
      &::before {
        pointer-events: none;
        position: absolute;
        content: "";
        border-style: solid;
        -webkit-transition-duration: 0.3s;
        transition-duration: 0.3s;
        -webkit-transform: translateY(15px);
        transform: translateY(15px);
        opacity: 1;
        left: calc(50% - 15px);
        bottom: -5px;
        z-index: 999;
        border-width: 15px 15px 0 15px;
        border-color: #1c64f2 transparent transparent transparent;
      }
    }
    .echart-item-box-one {
      cursor: pointer;
      width: 108px;
      height: 108px;
      background: #fff;
      border-radius: 54px;
      display: flex;
      justify-content: center;
      align-items: center;
      .echart-item-box-two {
        width: 94px;
        height: 94px;
        background: #f9fafb;
        border-radius: 47px;
        display: flex;
        justify-content: center;
        align-items: center;
        .echart-item-box-three {
          position: absolute;
          width: 72px;
          height: 72px;
          background: #fff;
          border-radius: 36px;
          box-shadow: 0px 0px 7px 0px rgba(3, 24, 61, 0.19);
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          color: #111928;
          .three-title {
            width: 80%;
            text-align: center;
            font-size: 14px;
            font-weight: normal;
            line-height: 22px;
            letter-spacing: -0.01px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-text-overflow: ellipsis;
          }
          .three-num {
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 1px;
          }
        }
      }
    }
    .echart-item-box-one:hover {
      background: #ebf5ff;
      transition: all 0.5s;
      .echart-item-box-two {
        background: #ebf5ff;
        transition: all 0.5s;
      }
    }
  }
  .sanjiao-active {
    position: relative;
    margin-right: 10px;
    &::before {
      pointer-events: none;
      position: absolute;
      content: "";
      border-style: solid;
      -webkit-transition-duration: 0.3s;
      transition-duration: 0.3s;
      -webkit-transform: translateY(15px);
      transform: translateY(15px);
      opacity: 1;
      left: calc(50% - 15px);
      bottom: -5px;
      z-index: 999;
      border-width: 15px 15px 0 15px;
      border-color: #1c64f2 transparent transparent transparent;
    }
    .echart-item-box-one {
      cursor: pointer;
      width: 108px;
      height: 108px;
      background: #ebf5ff;
      border-radius: 54px;
      display: flex;
      justify-content: center;
      align-items: center;
      .echart-item-box-two {
        width: 94px;
        height: 94px;
        background: #ebf5ff;
        border-radius: 47px;
        display: flex;
        justify-content: center;
        align-items: center;
        .echart-item-box-three {
          position: absolute;
          width: 72px;
          height: 72px;
          background: #fff;
          border-radius: 36px;
          box-shadow: 0px 0px 7px 0px rgba(3, 24, 61, 0.19);
          display: flex;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          color: #111928;
          .three-title {
            width: 80%;
            text-align: center;
            font-size: 14px;
            font-weight: normal;
            line-height: 22px;
            letter-spacing: -0.01px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-text-overflow: ellipsis;
          }
          .three-num {
            font-size: 18px;
            font-weight: 500;
            letter-spacing: 1px;
          }
        }
      }
    }
    .echart-item-box-one:hover {
      background: #ebf5ff;
      .echart-item-box-two {
        background: #ebf5ff;
      }
    }
  }

  .fill-file-address {
    display: flex;
    width: 100%;
    .please-box {
      cursor: pointer;
    }
    .iconfont {
      color: #1c64f2;
      margin-right: 8px;
    }
    .please {
      height: 32px;
      cursor: pointer;
      font-size: 14px;
      font-weight: normal;
      line-height: 32px;
      letter-spacing: -0.01px;
      text-decoration: underline;
      color: #1c64f2;
    }

    .fill-file-address-url {
      cursor: pointer;
      flex: 1;
      color: #1c64f2;
      white-space: nowrap;
      overflow: hidden;
      text-decoration: underline;
      text-overflow: ellipsis;
      -webkit-text-overflow: ellipsis;
    }
  }
  .have-url-file {
    display: flex;
    align-items: center;
    width: 100%;

    .icon-pencil {
      cursor: pointer;
      width: 20px;
    }
  }
  .personLiable-box {
    display: flex;
    align-items: center;
    .nodeOutput-item {
      .gray-text {
        font-size: 14px;
        font-weight: normal;
        line-height: 22px;
        letter-spacing: -0.01px;
        color: #9ca3af;
      }
      .blue-text {
        text-decoration: underline;
      }
    }
    .is-me {
      width: fit-content;
      padding: 2px 6px;
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      letter-spacing: -0.01px;
      border-radius: 13px;
      height: 26px;
      border: 1px solid #1c64f2;
      color: #1c64f2;
      margin: 0 3px;
    }
    .not-me {
      margin: 0 3px;
    }
  }
  .time-plan {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .iconfont {
      width: 20px;
      height: 20px;
      color: #1c64f2;
      cursor: pointer;
    }
    p {
      color: #1c64f2;
      cursor: pointer;
    }
  }
  .button:first-child {
    .extend {
      left: 0;
      top: 0;
    }
  }

  .button:hover {
    .extend {
      width: 60px;
    }
  }
  .center-btn {
    display: flex;
    align-items: center;
    .icon-refresh {
      margin-right: 5px;
    }
    .icon-eye {
      margin-right: 5px;
    }
  }
  .ant-form-item-with-help {
    margin-bottom: 0px;
  }
}
.resume-modal {
  .ant-form-item {
    margin-bottom: 21px;
  }
}
.task-detail-update-modal {
  .ant-input-number,
  .ant-input-number-group-addon {
    border-radius: 2px;
  }
  // 更新/完成表单节点
  .update-compelete-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .work-hour-item {
    display: inline-block;
  }
  .complete-dialog{
    .work-hour-tip{
      margin-left: 110px;
      font-size: 12px;
      font-weight: normal;
      display: flex;
      align-items: center;
      letter-spacing: -0.01px;
      color: #E73737;
      .icon{
        transform: scale(0.5);
      }
    }
    .work-hour-label::before {
      display: inline-block;
      color: red;
      content: "*";
      font-family: "SimSun,sans-serif";
      margin-inline-end: 4px;
    }
  }
  
  .ant-modal-footer {
    padding: 0 50px;
  }
}

$primaryColor: #1fa6d7;
$fontColor1: #101820;
$grayBackground: #f7f8fa;

.project-type-circle-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // .customer-center-label {
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, -50%);
  //   display: flex;
  //   flex-direction: column;
  //   text-align: center;
  //   width: 80px;
  //   .label-num {
  //     font-family: "Oswald";
  //     font-size: 20px;
  //     font-weight: 500;
  //     line-height: 28px;
  //     color: #374151;
  //   }
  //   .label-desc {
  //     font-family: "思源黑体 CN";
  //     font-size: 12px;
  //     line-height: 20px;
  //     color: #6b7280;
  //   }
  // }
}
// 产能分析-tab页
.efficiency-analysis-container {
  .ant-picker.ant-picker-range {
    border-color: #e5e7eb !important;
    border-radius: 2px;
    box-sizing: border-box;
    border: 1px solid #e5e7eb;
  }
  .rectangle {
    width: 4px;
    height: 14px;
    background: #3f83f8;
  }
  .title-container {
    background: #f3f4f6;
    height: 44px;
    display: flex;
    align-items: center;
    padding: 15px;
    margin-top: 20px;
    span {
      color: #374151;
      font-family: "思源黑体 CN";
      font-size: 14px;
      font-weight: 500;
    }
    // 产品名
    .product-name {
      margin-left: 8px;
    }
    //  协同模式
    .cooperate-mode {
      margin-left: 15px;
    }
    // 导出明细
    .export-botton {
      span {
        color: #3f83f8;
        font-weight: normal;
      }
    }
  }
  // 图表统计
  .chart-container {
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
    height: 180px;
    border-radius: 2px;
    box-sizing: border-box;
    border: 1px solid #e3e3e3;
    display: flex;
    .project-type-circle-container {
      position: relative;
      width: 33%;
      display: flex;
      text-align: center;
      .project-name {
        position: relative;
        top: -43%;
        left: 0;
        // transform: translate(-50%, -50%);
      }
    }
  }
}

// 按设备统计
.device-statistic-container {
  .header-container {
    display: flex;
    justify-content: space-between;
    .staff-input,
    .staff-range-picker {
      border-color: #e5e7eb !important;
      border-radius: 2px;
      box-sizing: border-box;
      border: 1px solid #e5e7eb;
    }
    .button-export {
      display: flex;
      align-items: center;
      justify-items: center;
      gap: 2px;
      .span {
        font-family: "思源黑体 CN";
        font-size: 14px;
        line-height: 22px;
        color: #ffffff;
      }
      i {
        height: 24px;
      }
    }
    .custom-device-search {
      button {
        border-left: none;
      }
    }
  }
  .custom-table {
    margin-top: 16px;
    .column-percent-container {
    }
  }
}
// 按人员统计
.staff-statistic-container {
  .header-container {
    display: flex;
    justify-content: space-between;
    height: 50px;
    .staff-input,
    .staff-range-picker {
      border-color: #e5e7eb !important;
      border-radius: 2px;
      box-sizing: border-box;
      border: 1px solid #e5e7eb;
    }
    .button-export {
      display: flex;
      justify-content: center;
      align-items: center;
      i {
        height: 24px;
      }
    }
    .custom-staff-search {
      button {
        border-left: none;
      }
    }
  }
  .custom-table {
    .operation {
      .button:first-child {
        .extend {
          left: 0;
        }
      }
      .button:first-child:hover {
        .extend {
          max-width: 110px;
          width: 90px;
        }
      }
    }
  }
}
// table百分比列样式
.column-percent-container {
  display: flex;
  gap: 10px;

  .custom-progress {
    align-self: center;
  }
}

// 登录明细弹框
.login-detail-modal {
  width: 700px !important;
  height: 556px;
  .button-export {
    display: block;
    margin-top: -8px;
    margin-left: auto;
    width: 82px;
  }
  .custom-table {
    margin-top: 15px;
  }
}
